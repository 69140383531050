.skeleton {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.green-skeleton {
  opacity: .7;
  animation: skeleton-green-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  border-radius: .125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: #a3b8c2;
  }

  100% {
    background-color: #f0f3f5;
  }
}

@keyframes skeleton-green-loading {
  0% {
    background-color: #00E87E;
  }

  100% {
    background-color: #4be29e;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 12px;
}

.lds-ellipsis div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 42px;
  animation: lds-ellipsis3 0.6s infinite;
}

.circular {
  border-radius: 50%;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(20px, 0);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 70px;
  height: 70px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 49px;
  height: 49px;
  margin: 6px;
  border-radius: 50%;
  border: 5px solid #28c16d7e;
  border-color: #28c16d7e transparent #28c16d7e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}