/* Avatar Variants Definitions */
.wizard {
  display: flex;
  align-items: center;
}

.wizard-step {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  white-space: nowrap;
}
.wizard-step--active .wizard-step__icon {
  background: #1EB7FF;
}
.wizard-step--active .wizard-step__content {
  color: #8493A5;
}
.wizard-step--disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.wizard-step--complete .wizard-step__icon {
  background: #1BB934;
}
.wizard-step__icon {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: #DEE2E6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-step__icon > * {
  color: #fff;
}
.wizard-step__content {
  color: #adb7c3;
}
.wizard-step + .wizard-step {
  margin-left: 20px;
}

@media (max-width: 991.98px) {
  .wizard {
    flex-wrap: wrap;
  }
  .wizard-step {
    flex: 0 1 50%;
    padding: 0.5rem;
  }
  .wizard-step + .wizard-step {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .wizard-step {
    flex-basis: 100%;
  }
}