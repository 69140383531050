/* Avatar Variants Definitions */
.sidebar--animations-enabled {
  /*
  &.sidebar--slim.sidebar--collapsed {
      // Slim Submenus
      .sidebar-menu__entry > .sidebar-submenu {
          display: block !important;
          opacity: 0;
          transform: translateY(-40px) scale(0.8);
          transition: opacity 200ms ease-in-out,
                      transform 200ms cubic-bezier(0.645, 0.045, 0.465, 1.305);
          transition-delay: 200ms;
      }

      .sidebar-menu__entry:hover > .sidebar-submenu {
          opacity: 1;
          transform: translateY(0) scale(1);
      }
  }
  */
}
.sidebar--animations-enabled.sidebar--animate-slim--progress .active > a {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #1EB7FF !important;
}
.sidebar--animations-enabled.sidebar--animate-slim--progress .sidebar-menu__entry__link::after,
.sidebar--animations-enabled.sidebar--animate-slim--progress .sidebar-submenu__entry__link::after {
  opacity: 0;
}
.sidebar--animations-enabled.sidebar--animate-slim--progress .sidebar-menu .badge {
  opacity: 0;
}
.sidebar--animations-enabled.sidebar--animate-entry-complete .sidebar__section {
  opacity: 1;
}
.sidebar--animations-enabled .sidebar__section {
  opacity: 0;
}
.sidebar--animations-enabled .sidebar-menu__entry__link::after {
  transition: opacity 200ms ease-in;
}
.sidebar--animations-enabled .sidebar-menu__entry.active .sidebar-menu__entry__link {
  transition: background-color 200ms ease-in-out box-shadow 200ms ease-in-out;
}
.sidebar--animations-enabled .sidbear-menu .badge {
  transition: opacity 200ms ease-in-out;
}

/* Avatar Variants Definitions */
/* Variant Generator */
/* Avatar Base */
.avatar {
  position: relative;
  display: inline-block;
}
.avatar__content {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.avatar__badge {
  position: absolute;
  display: block;
  transform: translate(50%, -50%);
  z-index: 1;
}
.avatar__icon {
  position: absolute;
  display: block;
  transform: translate(50%, 50%);
  z-index: 1;
}
.avatar__icon--stack > .fa {
  display: block;
}
.avatar__icon--stack > .fa ~ .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar-font {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.avatar-font__text {
  flex: 0 0 auto;
  font-size: 11px;
  font-weight: 600;
}

.avatar-font--sm .avatar-font__text {
  font-size: 10px;
}

.avatar-font--lg .avatar-font__text {
  font-size: 24px;
}

.avatar-image {
  display: inline-block;
  position: relative;
}
.avatar-image__image {
  position: relative;
  visibility: hidden;
  z-index: 0;
}
.avatar-image__image img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.avatar-image__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.avatar-image--loaded .avatar-image__image {
  visibility: visible;
}

.avatar--sm {
  width: 20px;
  height: 20px;
}
.avatar--sm .avatar__icon:not(.avatar__icon--nested) {
  right: 1px;
  bottom: 3px;
}
.avatar--sm .avatar__icon:not(.avatar__icon--nested) > *:first-child {
  font-size: 14px;
}
.avatar--sm .avatar__icon:not(.avatar__icon--nested) > * ~ * {
  font-size: 9.8px;
}
.avatar--sm .avatar__icon:not(.avatar__icon--nested) > * ~ *.avatar__icon--small {
  font-size: 5.46px;
}
.avatar--sm .avatar__icon.avatar__icon--nested {
  right: -2px;
  bottom: 3px;
}
.avatar--sm .avatar__icon.avatar__icon--nested > *:first-child {
  font-size: 25px;
}
.avatar--sm .avatar__icon.avatar__icon--nested > * ~ * {
  font-size: 19.5px;
}
.avatar--sm .avatar__icon.avatar__icon--nested > * ~ *.avatar__icon__inner {
  font-size: 9.75px;
}
.avatar--sm .avatar__badge {
  right: 1px;
  top: 1px;
}
.avatar--sm .avatar__badge .badge,
.avatar--sm .avatar__badge .label {
  font-size: 9px;
}

.avatar--md {
  width: 30px;
  height: 30px;
}
.avatar--md .avatar__icon:not(.avatar__icon--nested) {
  right: -1px;
  bottom: 5px;
}
.avatar--md .avatar__icon:not(.avatar__icon--nested) > *:first-child {
  font-size: 15px;
}
.avatar--md .avatar__icon:not(.avatar__icon--nested) > * ~ * {
  font-size: 10.5px;
}
.avatar--md .avatar__icon:not(.avatar__icon--nested) > * ~ *.avatar__icon--small {
  font-size: 5.4px;
}
.avatar--md .avatar__icon.avatar__icon--nested {
  right: 1px;
  bottom: 5px;
}
.avatar--md .avatar__icon.avatar__icon--nested > *:first-child {
  font-size: 25px;
}
.avatar--md .avatar__icon.avatar__icon--nested > * ~ * {
  font-size: 19.5px;
}
.avatar--md .avatar__icon.avatar__icon--nested > * ~ *.avatar__icon__inner {
  font-size: 9px;
}
.avatar--md .avatar__badge {
  right: 2px;
  top: 3px;
}

.avatar--lg {
  width: 60px;
  height: 60px;
}
.avatar--lg .avatar__icon:not(.avatar__icon--nested) {
  right: 10px;
  bottom: 8px;
}
.avatar--lg .avatar__icon:not(.avatar__icon--nested) > *:first-child {
  font-size: 15px;
}
.avatar--lg .avatar__icon:not(.avatar__icon--nested) > * ~ * {
  font-size: 10.5px;
}
.avatar--lg .avatar__icon:not(.avatar__icon--nested) > * ~ *.avatar__icon--small {
  font-size: 5.4px;
}
.avatar--lg .avatar__icon.avatar__icon--nested {
  right: 10px;
  bottom: 8px;
}
.avatar--lg .avatar__icon.avatar__icon--nested > *:first-child {
  font-size: 25px;
}
.avatar--lg .avatar__icon.avatar__icon--nested > * ~ * {
  font-size: 19.5px;
}
.avatar--lg .avatar__icon.avatar__icon--nested > * ~ *.avatar__icon__inner {
  font-size: 9px;
}
.avatar--lg .avatar__badge {
  right: 10px;
  top: 5px;
}

/* Avatar Variants Definitions */
.custom-card--border-dash.custom-card--color-100, .custom-card--border-dot.custom-card--color-100, .custom-card--border.custom-card--color-100 {
  border-color: #F8F9FA;
}
.custom-card--border-dash.custom-card--color-200, .custom-card--border-dot.custom-card--color-200, .custom-card--border.custom-card--color-200 {
  border-color: #E9ECEF;
}
.custom-card--border-dash.custom-card--color-300, .custom-card--border-dot.custom-card--color-300, .custom-card--border.custom-card--color-300 {
  border-color: #DEE2E6;
}
.custom-card--border-dash.custom-card--color-400, .custom-card--border-dot.custom-card--color-400, .custom-card--border.custom-card--color-400 {
  border-color: #CED4DA;
}
.custom-card--border-dash.custom-card--color-500, .custom-card--border-dot.custom-card--color-500, .custom-card--border.custom-card--color-500 {
  border-color: #ADB5BD;
}
.custom-card--border-dash.custom-card--color-600, .custom-card--border-dot.custom-card--color-600, .custom-card--border.custom-card--color-600 {
  border-color: #868E96;
}
.custom-card--border-dash.custom-card--color-700, .custom-card--border-dot.custom-card--color-700, .custom-card--border.custom-card--color-700 {
  border-color: #5D636D;
}
.custom-card--border-dash.custom-card--color-800, .custom-card--border-dot.custom-card--color-800, .custom-card--border.custom-card--color-800 {
  border-color: #3F4651;
}
.custom-card--border-dash.custom-card--color-900, .custom-card--border-dot.custom-card--color-900, .custom-card--border.custom-card--color-900 {
  border-color: #353C48;
}
.custom-card--border-dash.custom-card--color-blue, .custom-card--border-dot.custom-card--color-blue, .custom-card--border.custom-card--color-blue {
  border-color: #1EB7FF;
}
.custom-card--border-dash.custom-card--color-indigo, .custom-card--border-dot.custom-card--color-indigo, .custom-card--border.custom-card--color-indigo {
  border-color: #6610f2;
}
.custom-card--border-dash.custom-card--color-purple, .custom-card--border-dot.custom-card--color-purple, .custom-card--border.custom-card--color-purple {
  border-color: #CA8EFF;
}
.custom-card--border-dash.custom-card--color-pink, .custom-card--border-dot.custom-card--color-pink, .custom-card--border.custom-card--color-pink {
  border-color: #e83e8c;
}
.custom-card--border-dash.custom-card--color-red, .custom-card--border-dot.custom-card--color-red, .custom-card--border.custom-card--color-red {
  border-color: #ED1C24;
}
.custom-card--border-dash.custom-card--color-orange, .custom-card--border-dot.custom-card--color-orange, .custom-card--border.custom-card--color-orange {
  border-color: #F27212;
}
.custom-card--border-dash.custom-card--color-yellow, .custom-card--border-dot.custom-card--color-yellow, .custom-card--border.custom-card--color-yellow {
  border-color: #F7BF47;
}
.custom-card--border-dash.custom-card--color-green, .custom-card--border-dot.custom-card--color-green, .custom-card--border.custom-card--color-green {
  border-color: #1BB934;
}
.custom-card--border-dash.custom-card--color-teal, .custom-card--border-dot.custom-card--color-teal, .custom-card--border.custom-card--color-teal {
  border-color: #20c997;
}
.custom-card--border-dash.custom-card--color-cyan, .custom-card--border-dot.custom-card--color-cyan, .custom-card--border.custom-card--color-cyan {
  border-color: #33AE9A;
}
.custom-card--border-dash.custom-card--color-white, .custom-card--border-dot.custom-card--color-white, .custom-card--border.custom-card--color-white {
  border-color: #FFF;
}
.custom-card--border-dash.custom-card--color-gray, .custom-card--border-dot.custom-card--color-gray, .custom-card--border.custom-card--color-gray {
  border-color: #868E96;
}
.custom-card--border-dash.custom-card--color-gray-dark, .custom-card--border-dot.custom-card--color-gray-dark, .custom-card--border.custom-card--color-gray-dark {
  border-color: #3F4651;
}
.custom-card--border-dash.custom-card--color-facebook, .custom-card--border-dot.custom-card--color-facebook, .custom-card--border.custom-card--color-facebook {
  border-color: #3A5E95;
}
.custom-card--border-dash.custom-card--color-twitter, .custom-card--border-dot.custom-card--color-twitter, .custom-card--border.custom-card--color-twitter {
  border-color: #60ADDB;
}
.custom-card--border-dash.custom-card--color-lastfm, .custom-card--border-dot.custom-card--color-lastfm, .custom-card--border.custom-card--color-lastfm {
  border-color: #E01132;
}
.custom-card--border-dash.custom-card--color-pinterest, .custom-card--border-dot.custom-card--color-pinterest, .custom-card--border.custom-card--color-pinterest {
  border-color: #BD0520;
}
.custom-card--border-dash.custom-card--color-linkedin, .custom-card--border-dot.custom-card--color-linkedin, .custom-card--border.custom-card--color-linkedin {
  border-color: #057CB2;
}
.custom-card--border-dash.custom-card--color-medium, .custom-card--border-dot.custom-card--color-medium, .custom-card--border.custom-card--color-medium {
  border-color: #5cb85c;
}
.custom-card--border-dash.custom-card--color-skype, .custom-card--border-dot.custom-card--color-skype, .custom-card--border.custom-card--color-skype {
  border-color: #0AB2EF;
}
.custom-card--border-dash.custom-card--color-foursquare, .custom-card--border-dot.custom-card--color-foursquare, .custom-card--border.custom-card--color-foursquare {
  border-color: #F84978;
}
.custom-card--border-dash.custom-card--color-android, .custom-card--border-dot.custom-card--color-android, .custom-card--border.custom-card--color-android {
  border-color: #A6C843;
}
.custom-card--border-dash.custom-card--color-spotify, .custom-card--border-dot.custom-card--color-spotify, .custom-card--border.custom-card--color-spotify {
  border-color: #2FD665;
}
.custom-card--border-dash.custom-card--color-paypal, .custom-card--border-dot.custom-card--color-paypal, .custom-card--border.custom-card--color-paypal {
  border-color: #2A9AD7;
}
.custom-card--border-dash.custom-card--color-dribble, .custom-card--border-dot.custom-card--color-dribble, .custom-card--border.custom-card--color-dribble {
  border-color: #F16898;
}
.custom-card--border-dash.custom-card--color-youtube, .custom-card--border-dot.custom-card--color-youtube, .custom-card--border.custom-card--color-youtube {
  border-color: #ED1922;
}
.custom-card--border-dash.custom-card--color-windows, .custom-card--border-dot.custom-card--color-windows, .custom-card--border.custom-card--color-windows {
  border-color: #10BFF2;
}
.custom-card--border-dash.custom-card--color-amazon, .custom-card--border-dot.custom-card--color-amazon, .custom-card--border.custom-card--color-amazon {
  border-color: #FEA320;
}
.custom-card--border-dash.custom-card--color-black-01, .custom-card--border-dot.custom-card--color-black-01, .custom-card--border.custom-card--color-black-01 {
  border-color: rgba(31, 45, 61, 0.1);
}
.custom-card--border-dash.custom-card--color-black-02, .custom-card--border-dot.custom-card--color-black-02, .custom-card--border.custom-card--color-black-02 {
  border-color: rgba(31, 45, 61, 0.2);
}
.custom-card--border-dash.custom-card--color-black-03, .custom-card--border-dot.custom-card--color-black-03, .custom-card--border.custom-card--color-black-03 {
  border-color: rgba(31, 45, 61, 0.3);
}
.custom-card--border-dash.custom-card--color-black-04, .custom-card--border-dot.custom-card--color-black-04, .custom-card--border.custom-card--color-black-04 {
  border-color: rgba(31, 45, 61, 0.4);
}
.custom-card--border-dash.custom-card--color-black-05, .custom-card--border-dot.custom-card--color-black-05, .custom-card--border.custom-card--color-black-05 {
  border-color: rgba(31, 45, 61, 0.5);
}
.custom-card--border-dash.custom-card--color-black-06, .custom-card--border-dot.custom-card--color-black-06, .custom-card--border.custom-card--color-black-06 {
  border-color: rgba(31, 45, 61, 0.6);
}
.custom-card--border-dash.custom-card--color-black-07, .custom-card--border-dot.custom-card--color-black-07, .custom-card--border.custom-card--color-black-07 {
  border-color: rgba(31, 45, 61, 0.7);
}
.custom-card--border-dash.custom-card--color-black-08, .custom-card--border-dot.custom-card--color-black-08, .custom-card--border.custom-card--color-black-08 {
  border-color: rgba(31, 45, 61, 0.8);
}
.custom-card--border-dash.custom-card--color-black-09, .custom-card--border-dot.custom-card--color-black-09, .custom-card--border.custom-card--color-black-09 {
  border-color: rgba(31, 45, 61, 0.9);
}
.custom-card--border-dash.custom-card--color-white-01, .custom-card--border-dot.custom-card--color-white-01, .custom-card--border.custom-card--color-white-01 {
  border-color: rgba(255, 255, 255, 0.1);
}
.custom-card--border-dash.custom-card--color-white-02, .custom-card--border-dot.custom-card--color-white-02, .custom-card--border.custom-card--color-white-02 {
  border-color: rgba(255, 255, 255, 0.2);
}
.custom-card--border-dash.custom-card--color-white-03, .custom-card--border-dot.custom-card--color-white-03, .custom-card--border.custom-card--color-white-03 {
  border-color: rgba(255, 255, 255, 0.3);
}
.custom-card--border-dash.custom-card--color-white-04, .custom-card--border-dot.custom-card--color-white-04, .custom-card--border.custom-card--color-white-04 {
  border-color: rgba(255, 255, 255, 0.4);
}
.custom-card--border-dash.custom-card--color-white-05, .custom-card--border-dot.custom-card--color-white-05, .custom-card--border.custom-card--color-white-05 {
  border-color: rgba(255, 255, 255, 0.5);
}
.custom-card--border-dash.custom-card--color-white-06, .custom-card--border-dot.custom-card--color-white-06, .custom-card--border.custom-card--color-white-06 {
  border-color: rgba(255, 255, 255, 0.6);
}
.custom-card--border-dash.custom-card--color-white-07, .custom-card--border-dot.custom-card--color-white-07, .custom-card--border.custom-card--color-white-07 {
  border-color: rgba(255, 255, 255, 0.7);
}
.custom-card--border-dash.custom-card--color-white-08, .custom-card--border-dot.custom-card--color-white-08, .custom-card--border.custom-card--color-white-08 {
  border-color: rgba(255, 255, 255, 0.8);
}
.custom-card--border-dash.custom-card--color-white-09, .custom-card--border-dot.custom-card--color-white-09, .custom-card--border.custom-card--color-white-09 {
  border-color: rgba(255, 255, 255, 0.9);
}
.custom-card--border-dash.custom-card--color-primary-01, .custom-card--border-dot.custom-card--color-primary-01, .custom-card--border.custom-card--color-primary-01 {
  border-color: rgba(30, 183, 255, 0.1);
}
.custom-card--border-dash.custom-card--color-primary-02, .custom-card--border-dot.custom-card--color-primary-02, .custom-card--border.custom-card--color-primary-02 {
  border-color: rgba(30, 183, 255, 0.2);
}
.custom-card--border-dash.custom-card--color-primary-03, .custom-card--border-dot.custom-card--color-primary-03, .custom-card--border.custom-card--color-primary-03 {
  border-color: rgba(30, 183, 255, 0.3);
}
.custom-card--border-dash.custom-card--color-primary-04, .custom-card--border-dot.custom-card--color-primary-04, .custom-card--border.custom-card--color-primary-04 {
  border-color: rgba(30, 183, 255, 0.4);
}
.custom-card--border-dash.custom-card--color-primary-05, .custom-card--border-dot.custom-card--color-primary-05, .custom-card--border.custom-card--color-primary-05 {
  border-color: rgba(30, 183, 255, 0.5);
}
.custom-card--border-dash.custom-card--color-primary-06, .custom-card--border-dot.custom-card--color-primary-06, .custom-card--border.custom-card--color-primary-06 {
  border-color: rgba(30, 183, 255, 0.6);
}
.custom-card--border-dash.custom-card--color-primary-07, .custom-card--border-dot.custom-card--color-primary-07, .custom-card--border.custom-card--color-primary-07 {
  border-color: rgba(30, 183, 255, 0.7);
}
.custom-card--border-dash.custom-card--color-primary-08, .custom-card--border-dot.custom-card--color-primary-08, .custom-card--border.custom-card--color-primary-08 {
  border-color: rgba(30, 183, 255, 0.8);
}
.custom-card--border-dash.custom-card--color-primary-09, .custom-card--border-dot.custom-card--color-primary-09, .custom-card--border.custom-card--color-primary-09 {
  border-color: rgba(30, 183, 255, 0.9);
}
.custom-card--border-dash.custom-card--color-info-01, .custom-card--border-dot.custom-card--color-info-01, .custom-card--border.custom-card--color-info-01 {
  border-color: rgba(51, 174, 154, 0.1);
}
.custom-card--border-dash.custom-card--color-info-02, .custom-card--border-dot.custom-card--color-info-02, .custom-card--border.custom-card--color-info-02 {
  border-color: rgba(51, 174, 154, 0.2);
}
.custom-card--border-dash.custom-card--color-info-03, .custom-card--border-dot.custom-card--color-info-03, .custom-card--border.custom-card--color-info-03 {
  border-color: rgba(51, 174, 154, 0.3);
}
.custom-card--border-dash.custom-card--color-info-04, .custom-card--border-dot.custom-card--color-info-04, .custom-card--border.custom-card--color-info-04 {
  border-color: rgba(51, 174, 154, 0.4);
}
.custom-card--border-dash.custom-card--color-info-05, .custom-card--border-dot.custom-card--color-info-05, .custom-card--border.custom-card--color-info-05 {
  border-color: rgba(51, 174, 154, 0.5);
}
.custom-card--border-dash.custom-card--color-info-06, .custom-card--border-dot.custom-card--color-info-06, .custom-card--border.custom-card--color-info-06 {
  border-color: rgba(51, 174, 154, 0.6);
}
.custom-card--border-dash.custom-card--color-info-07, .custom-card--border-dot.custom-card--color-info-07, .custom-card--border.custom-card--color-info-07 {
  border-color: rgba(51, 174, 154, 0.7);
}
.custom-card--border-dash.custom-card--color-info-08, .custom-card--border-dot.custom-card--color-info-08, .custom-card--border.custom-card--color-info-08 {
  border-color: rgba(51, 174, 154, 0.8);
}
.custom-card--border-dash.custom-card--color-info-09, .custom-card--border-dot.custom-card--color-info-09, .custom-card--border.custom-card--color-info-09 {
  border-color: rgba(51, 174, 154, 0.9);
}
.custom-card--border-dash.custom-card--color-success-01, .custom-card--border-dot.custom-card--color-success-01, .custom-card--border.custom-card--color-success-01 {
  border-color: rgba(27, 185, 52, 0.1);
}
.custom-card--border-dash.custom-card--color-success-02, .custom-card--border-dot.custom-card--color-success-02, .custom-card--border.custom-card--color-success-02 {
  border-color: rgba(27, 185, 52, 0.2);
}
.custom-card--border-dash.custom-card--color-success-03, .custom-card--border-dot.custom-card--color-success-03, .custom-card--border.custom-card--color-success-03 {
  border-color: rgba(27, 185, 52, 0.3);
}
.custom-card--border-dash.custom-card--color-success-04, .custom-card--border-dot.custom-card--color-success-04, .custom-card--border.custom-card--color-success-04 {
  border-color: rgba(27, 185, 52, 0.4);
}
.custom-card--border-dash.custom-card--color-success-05, .custom-card--border-dot.custom-card--color-success-05, .custom-card--border.custom-card--color-success-05 {
  border-color: rgba(27, 185, 52, 0.5);
}
.custom-card--border-dash.custom-card--color-success-06, .custom-card--border-dot.custom-card--color-success-06, .custom-card--border.custom-card--color-success-06 {
  border-color: rgba(27, 185, 52, 0.6);
}
.custom-card--border-dash.custom-card--color-success-07, .custom-card--border-dot.custom-card--color-success-07, .custom-card--border.custom-card--color-success-07 {
  border-color: rgba(27, 185, 52, 0.7);
}
.custom-card--border-dash.custom-card--color-success-08, .custom-card--border-dot.custom-card--color-success-08, .custom-card--border.custom-card--color-success-08 {
  border-color: rgba(27, 185, 52, 0.8);
}
.custom-card--border-dash.custom-card--color-success-09, .custom-card--border-dot.custom-card--color-success-09, .custom-card--border.custom-card--color-success-09 {
  border-color: rgba(27, 185, 52, 0.9);
}
.custom-card--border-dash.custom-card--color-warning-01, .custom-card--border-dot.custom-card--color-warning-01, .custom-card--border.custom-card--color-warning-01 {
  border-color: rgba(242, 114, 18, 0.1);
}
.custom-card--border-dash.custom-card--color-warning-02, .custom-card--border-dot.custom-card--color-warning-02, .custom-card--border.custom-card--color-warning-02 {
  border-color: rgba(242, 114, 18, 0.2);
}
.custom-card--border-dash.custom-card--color-warning-03, .custom-card--border-dot.custom-card--color-warning-03, .custom-card--border.custom-card--color-warning-03 {
  border-color: rgba(242, 114, 18, 0.3);
}
.custom-card--border-dash.custom-card--color-warning-04, .custom-card--border-dot.custom-card--color-warning-04, .custom-card--border.custom-card--color-warning-04 {
  border-color: rgba(242, 114, 18, 0.4);
}
.custom-card--border-dash.custom-card--color-warning-05, .custom-card--border-dot.custom-card--color-warning-05, .custom-card--border.custom-card--color-warning-05 {
  border-color: rgba(242, 114, 18, 0.5);
}
.custom-card--border-dash.custom-card--color-warning-06, .custom-card--border-dot.custom-card--color-warning-06, .custom-card--border.custom-card--color-warning-06 {
  border-color: rgba(242, 114, 18, 0.6);
}
.custom-card--border-dash.custom-card--color-warning-07, .custom-card--border-dot.custom-card--color-warning-07, .custom-card--border.custom-card--color-warning-07 {
  border-color: rgba(242, 114, 18, 0.7);
}
.custom-card--border-dash.custom-card--color-warning-08, .custom-card--border-dot.custom-card--color-warning-08, .custom-card--border.custom-card--color-warning-08 {
  border-color: rgba(242, 114, 18, 0.8);
}
.custom-card--border-dash.custom-card--color-warning-09, .custom-card--border-dot.custom-card--color-warning-09, .custom-card--border.custom-card--color-warning-09 {
  border-color: rgba(242, 114, 18, 0.9);
}
.custom-card--border-dash.custom-card--color-danger-01, .custom-card--border-dot.custom-card--color-danger-01, .custom-card--border.custom-card--color-danger-01 {
  border-color: rgba(237, 28, 36, 0.1);
}
.custom-card--border-dash.custom-card--color-danger-02, .custom-card--border-dot.custom-card--color-danger-02, .custom-card--border.custom-card--color-danger-02 {
  border-color: rgba(237, 28, 36, 0.2);
}
.custom-card--border-dash.custom-card--color-danger-03, .custom-card--border-dot.custom-card--color-danger-03, .custom-card--border.custom-card--color-danger-03 {
  border-color: rgba(237, 28, 36, 0.3);
}
.custom-card--border-dash.custom-card--color-danger-04, .custom-card--border-dot.custom-card--color-danger-04, .custom-card--border.custom-card--color-danger-04 {
  border-color: rgba(237, 28, 36, 0.4);
}
.custom-card--border-dash.custom-card--color-danger-05, .custom-card--border-dot.custom-card--color-danger-05, .custom-card--border.custom-card--color-danger-05 {
  border-color: rgba(237, 28, 36, 0.5);
}
.custom-card--border-dash.custom-card--color-danger-06, .custom-card--border-dot.custom-card--color-danger-06, .custom-card--border.custom-card--color-danger-06 {
  border-color: rgba(237, 28, 36, 0.6);
}
.custom-card--border-dash.custom-card--color-danger-07, .custom-card--border-dot.custom-card--color-danger-07, .custom-card--border.custom-card--color-danger-07 {
  border-color: rgba(237, 28, 36, 0.7);
}
.custom-card--border-dash.custom-card--color-danger-08, .custom-card--border-dot.custom-card--color-danger-08, .custom-card--border.custom-card--color-danger-08 {
  border-color: rgba(237, 28, 36, 0.8);
}
.custom-card--border-dash.custom-card--color-danger-09, .custom-card--border-dot.custom-card--color-danger-09, .custom-card--border.custom-card--color-danger-09 {
  border-color: rgba(237, 28, 36, 0.9);
}
.custom-card--border-dash.custom-card--color-yellow-01, .custom-card--border-dot.custom-card--color-yellow-01, .custom-card--border.custom-card--color-yellow-01 {
  border-color: rgba(247, 191, 71, 0.1);
}
.custom-card--border-dash.custom-card--color-yellow-02, .custom-card--border-dot.custom-card--color-yellow-02, .custom-card--border.custom-card--color-yellow-02 {
  border-color: rgba(247, 191, 71, 0.2);
}
.custom-card--border-dash.custom-card--color-yellow-03, .custom-card--border-dot.custom-card--color-yellow-03, .custom-card--border.custom-card--color-yellow-03 {
  border-color: rgba(247, 191, 71, 0.3);
}
.custom-card--border-dash.custom-card--color-yellow-04, .custom-card--border-dot.custom-card--color-yellow-04, .custom-card--border.custom-card--color-yellow-04 {
  border-color: rgba(247, 191, 71, 0.4);
}
.custom-card--border-dash.custom-card--color-yellow-05, .custom-card--border-dot.custom-card--color-yellow-05, .custom-card--border.custom-card--color-yellow-05 {
  border-color: rgba(247, 191, 71, 0.5);
}
.custom-card--border-dash.custom-card--color-yellow-06, .custom-card--border-dot.custom-card--color-yellow-06, .custom-card--border.custom-card--color-yellow-06 {
  border-color: rgba(247, 191, 71, 0.6);
}
.custom-card--border-dash.custom-card--color-yellow-07, .custom-card--border-dot.custom-card--color-yellow-07, .custom-card--border.custom-card--color-yellow-07 {
  border-color: rgba(247, 191, 71, 0.7);
}
.custom-card--border-dash.custom-card--color-yellow-08, .custom-card--border-dot.custom-card--color-yellow-08, .custom-card--border.custom-card--color-yellow-08 {
  border-color: rgba(247, 191, 71, 0.8);
}
.custom-card--border-dash.custom-card--color-yellow-09, .custom-card--border-dot.custom-card--color-yellow-09, .custom-card--border.custom-card--color-yellow-09 {
  border-color: rgba(247, 191, 71, 0.9);
}
.custom-card--border-dash.custom-card--color-secondary-01, .custom-card--border-dot.custom-card--color-secondary-01, .custom-card--border.custom-card--color-secondary-01 {
  border-color: rgba(134, 142, 150, 0.1);
}
.custom-card--border-dash.custom-card--color-secondary-02, .custom-card--border-dot.custom-card--color-secondary-02, .custom-card--border.custom-card--color-secondary-02 {
  border-color: rgba(134, 142, 150, 0.2);
}
.custom-card--border-dash.custom-card--color-secondary-03, .custom-card--border-dot.custom-card--color-secondary-03, .custom-card--border.custom-card--color-secondary-03 {
  border-color: rgba(134, 142, 150, 0.3);
}
.custom-card--border-dash.custom-card--color-secondary-04, .custom-card--border-dot.custom-card--color-secondary-04, .custom-card--border.custom-card--color-secondary-04 {
  border-color: rgba(134, 142, 150, 0.4);
}
.custom-card--border-dash.custom-card--color-secondary-05, .custom-card--border-dot.custom-card--color-secondary-05, .custom-card--border.custom-card--color-secondary-05 {
  border-color: rgba(134, 142, 150, 0.5);
}
.custom-card--border-dash.custom-card--color-secondary-06, .custom-card--border-dot.custom-card--color-secondary-06, .custom-card--border.custom-card--color-secondary-06 {
  border-color: rgba(134, 142, 150, 0.6);
}
.custom-card--border-dash.custom-card--color-secondary-07, .custom-card--border-dot.custom-card--color-secondary-07, .custom-card--border.custom-card--color-secondary-07 {
  border-color: rgba(134, 142, 150, 0.7);
}
.custom-card--border-dash.custom-card--color-secondary-08, .custom-card--border-dot.custom-card--color-secondary-08, .custom-card--border.custom-card--color-secondary-08 {
  border-color: rgba(134, 142, 150, 0.8);
}
.custom-card--border-dash.custom-card--color-secondary-09, .custom-card--border-dot.custom-card--color-secondary-09, .custom-card--border.custom-card--color-secondary-09 {
  border-color: rgba(134, 142, 150, 0.9);
}
.custom-card--border-dash.custom-card--color-dark-01, .custom-card--border-dot.custom-card--color-dark-01, .custom-card--border.custom-card--color-dark-01 {
  border-color: rgba(63, 70, 81, 0.1);
}
.custom-card--border-dash.custom-card--color-dark-02, .custom-card--border-dot.custom-card--color-dark-02, .custom-card--border.custom-card--color-dark-02 {
  border-color: rgba(63, 70, 81, 0.2);
}
.custom-card--border-dash.custom-card--color-dark-03, .custom-card--border-dot.custom-card--color-dark-03, .custom-card--border.custom-card--color-dark-03 {
  border-color: rgba(63, 70, 81, 0.3);
}
.custom-card--border-dash.custom-card--color-dark-04, .custom-card--border-dot.custom-card--color-dark-04, .custom-card--border.custom-card--color-dark-04 {
  border-color: rgba(63, 70, 81, 0.4);
}
.custom-card--border-dash.custom-card--color-dark-05, .custom-card--border-dot.custom-card--color-dark-05, .custom-card--border.custom-card--color-dark-05 {
  border-color: rgba(63, 70, 81, 0.5);
}
.custom-card--border-dash.custom-card--color-dark-06, .custom-card--border-dot.custom-card--color-dark-06, .custom-card--border.custom-card--color-dark-06 {
  border-color: rgba(63, 70, 81, 0.6);
}
.custom-card--border-dash.custom-card--color-dark-07, .custom-card--border-dot.custom-card--color-dark-07, .custom-card--border.custom-card--color-dark-07 {
  border-color: rgba(63, 70, 81, 0.7);
}
.custom-card--border-dash.custom-card--color-dark-08, .custom-card--border-dot.custom-card--color-dark-08, .custom-card--border.custom-card--color-dark-08 {
  border-color: rgba(63, 70, 81, 0.8);
}
.custom-card--border-dash.custom-card--color-dark-09, .custom-card--border-dot.custom-card--color-dark-09, .custom-card--border.custom-card--color-dark-09 {
  border-color: rgba(63, 70, 81, 0.9);
}
.custom-card--border-dash.custom-card--color-light-01, .custom-card--border-dot.custom-card--color-light-01, .custom-card--border.custom-card--color-light-01 {
  border-color: rgba(248, 249, 250, 0.1);
}
.custom-card--border-dash.custom-card--color-light-02, .custom-card--border-dot.custom-card--color-light-02, .custom-card--border.custom-card--color-light-02 {
  border-color: rgba(248, 249, 250, 0.2);
}
.custom-card--border-dash.custom-card--color-light-03, .custom-card--border-dot.custom-card--color-light-03, .custom-card--border.custom-card--color-light-03 {
  border-color: rgba(248, 249, 250, 0.3);
}
.custom-card--border-dash.custom-card--color-light-04, .custom-card--border-dot.custom-card--color-light-04, .custom-card--border.custom-card--color-light-04 {
  border-color: rgba(248, 249, 250, 0.4);
}
.custom-card--border-dash.custom-card--color-light-05, .custom-card--border-dot.custom-card--color-light-05, .custom-card--border.custom-card--color-light-05 {
  border-color: rgba(248, 249, 250, 0.5);
}
.custom-card--border-dash.custom-card--color-light-06, .custom-card--border-dot.custom-card--color-light-06, .custom-card--border.custom-card--color-light-06 {
  border-color: rgba(248, 249, 250, 0.6);
}
.custom-card--border-dash.custom-card--color-light-07, .custom-card--border-dot.custom-card--color-light-07, .custom-card--border.custom-card--color-light-07 {
  border-color: rgba(248, 249, 250, 0.7);
}
.custom-card--border-dash.custom-card--color-light-08, .custom-card--border-dot.custom-card--color-light-08, .custom-card--border.custom-card--color-light-08 {
  border-color: rgba(248, 249, 250, 0.8);
}
.custom-card--border-dash.custom-card--color-light-09, .custom-card--border-dot.custom-card--color-light-09, .custom-card--border.custom-card--color-light-09 {
  border-color: rgba(248, 249, 250, 0.9);
}
.custom-card--border-dash.custom-card--color-purple-01, .custom-card--border-dot.custom-card--color-purple-01, .custom-card--border.custom-card--color-purple-01 {
  border-color: rgba(202, 142, 255, 0.1);
}
.custom-card--border-dash.custom-card--color-purple-02, .custom-card--border-dot.custom-card--color-purple-02, .custom-card--border.custom-card--color-purple-02 {
  border-color: rgba(202, 142, 255, 0.2);
}
.custom-card--border-dash.custom-card--color-purple-03, .custom-card--border-dot.custom-card--color-purple-03, .custom-card--border.custom-card--color-purple-03 {
  border-color: rgba(202, 142, 255, 0.3);
}
.custom-card--border-dash.custom-card--color-purple-04, .custom-card--border-dot.custom-card--color-purple-04, .custom-card--border.custom-card--color-purple-04 {
  border-color: rgba(202, 142, 255, 0.4);
}
.custom-card--border-dash.custom-card--color-purple-05, .custom-card--border-dot.custom-card--color-purple-05, .custom-card--border.custom-card--color-purple-05 {
  border-color: rgba(202, 142, 255, 0.5);
}
.custom-card--border-dash.custom-card--color-purple-06, .custom-card--border-dot.custom-card--color-purple-06, .custom-card--border.custom-card--color-purple-06 {
  border-color: rgba(202, 142, 255, 0.6);
}
.custom-card--border-dash.custom-card--color-purple-07, .custom-card--border-dot.custom-card--color-purple-07, .custom-card--border.custom-card--color-purple-07 {
  border-color: rgba(202, 142, 255, 0.7);
}
.custom-card--border-dash.custom-card--color-purple-08, .custom-card--border-dot.custom-card--color-purple-08, .custom-card--border.custom-card--color-purple-08 {
  border-color: rgba(202, 142, 255, 0.8);
}
.custom-card--border-dash.custom-card--color-purple-09, .custom-card--border-dot.custom-card--color-purple-09, .custom-card--border.custom-card--color-purple-09 {
  border-color: rgba(202, 142, 255, 0.9);
}
.custom-card--border-dash.custom-card--color-primary, .custom-card--border-dot.custom-card--color-primary, .custom-card--border.custom-card--color-primary {
  border-color: #1EB7FF;
}
.custom-card--border-dash.custom-card--color-secondary, .custom-card--border-dot.custom-card--color-secondary, .custom-card--border.custom-card--color-secondary {
  border-color: #868E96;
}
.custom-card--border-dash.custom-card--color-success, .custom-card--border-dot.custom-card--color-success, .custom-card--border.custom-card--color-success {
  border-color: #1BB934;
}
.custom-card--border-dash.custom-card--color-info, .custom-card--border-dot.custom-card--color-info, .custom-card--border.custom-card--color-info {
  border-color: #33AE9A;
}
.custom-card--border-dash.custom-card--color-warning, .custom-card--border-dot.custom-card--color-warning, .custom-card--border.custom-card--color-warning {
  border-color: #F27212;
}
.custom-card--border-dash.custom-card--color-danger, .custom-card--border-dot.custom-card--color-danger, .custom-card--border.custom-card--color-danger {
  border-color: #ED1C24;
}
.custom-card--border-dash.custom-card--color-light, .custom-card--border-dot.custom-card--color-light, .custom-card--border.custom-card--color-light {
  border-color: #F8F9FA;
}
.custom-card--border-dash.custom-card--color-dark, .custom-card--border-dot.custom-card--color-dark, .custom-card--border.custom-card--color-dark {
  border-color: #3F4651;
}
.custom-card--side-border {
  border-left-width: 2px;
}
.custom-card--side-border.custom-card--color-100 {
  border-left-color: #F8F9FA;
}
.custom-card--side-border.custom-card--color-200 {
  border-left-color: #E9ECEF;
}
.custom-card--side-border.custom-card--color-300 {
  border-left-color: #DEE2E6;
}
.custom-card--side-border.custom-card--color-400 {
  border-left-color: #CED4DA;
}
.custom-card--side-border.custom-card--color-500 {
  border-left-color: #ADB5BD;
}
.custom-card--side-border.custom-card--color-600 {
  border-left-color: #868E96;
}
.custom-card--side-border.custom-card--color-700 {
  border-left-color: #5D636D;
}
.custom-card--side-border.custom-card--color-800 {
  border-left-color: #3F4651;
}
.custom-card--side-border.custom-card--color-900 {
  border-left-color: #353C48;
}
.custom-card--side-border.custom-card--color-blue {
  border-left-color: #1EB7FF;
}
.custom-card--side-border.custom-card--color-indigo {
  border-left-color: #6610f2;
}
.custom-card--side-border.custom-card--color-purple {
  border-left-color: #CA8EFF;
}
.custom-card--side-border.custom-card--color-pink {
  border-left-color: #e83e8c;
}
.custom-card--side-border.custom-card--color-red {
  border-left-color: #ED1C24;
}
.custom-card--side-border.custom-card--color-orange {
  border-left-color: #F27212;
}
.custom-card--side-border.custom-card--color-yellow {
  border-left-color: #F7BF47;
}
.custom-card--side-border.custom-card--color-green {
  border-left-color: #1BB934;
}
.custom-card--side-border.custom-card--color-teal {
  border-left-color: #20c997;
}
.custom-card--side-border.custom-card--color-cyan {
  border-left-color: #33AE9A;
}
.custom-card--side-border.custom-card--color-white {
  border-left-color: #FFF;
}
.custom-card--side-border.custom-card--color-gray {
  border-left-color: #868E96;
}
.custom-card--side-border.custom-card--color-gray-dark {
  border-left-color: #3F4651;
}
.custom-card--side-border.custom-card--color-facebook {
  border-left-color: #3A5E95;
}
.custom-card--side-border.custom-card--color-twitter {
  border-left-color: #60ADDB;
}
.custom-card--side-border.custom-card--color-lastfm {
  border-left-color: #E01132;
}
.custom-card--side-border.custom-card--color-pinterest {
  border-left-color: #BD0520;
}
.custom-card--side-border.custom-card--color-linkedin {
  border-left-color: #057CB2;
}
.custom-card--side-border.custom-card--color-medium {
  border-left-color: #5cb85c;
}
.custom-card--side-border.custom-card--color-skype {
  border-left-color: #0AB2EF;
}
.custom-card--side-border.custom-card--color-foursquare {
  border-left-color: #F84978;
}
.custom-card--side-border.custom-card--color-android {
  border-left-color: #A6C843;
}
.custom-card--side-border.custom-card--color-spotify {
  border-left-color: #2FD665;
}
.custom-card--side-border.custom-card--color-paypal {
  border-left-color: #2A9AD7;
}
.custom-card--side-border.custom-card--color-dribble {
  border-left-color: #F16898;
}
.custom-card--side-border.custom-card--color-youtube {
  border-left-color: #ED1922;
}
.custom-card--side-border.custom-card--color-windows {
  border-left-color: #10BFF2;
}
.custom-card--side-border.custom-card--color-amazon {
  border-left-color: #FEA320;
}
.custom-card--side-border.custom-card--color-black-01 {
  border-left-color: rgba(31, 45, 61, 0.1);
}
.custom-card--side-border.custom-card--color-black-02 {
  border-left-color: rgba(31, 45, 61, 0.2);
}
.custom-card--side-border.custom-card--color-black-03 {
  border-left-color: rgba(31, 45, 61, 0.3);
}
.custom-card--side-border.custom-card--color-black-04 {
  border-left-color: rgba(31, 45, 61, 0.4);
}
.custom-card--side-border.custom-card--color-black-05 {
  border-left-color: rgba(31, 45, 61, 0.5);
}
.custom-card--side-border.custom-card--color-black-06 {
  border-left-color: rgba(31, 45, 61, 0.6);
}
.custom-card--side-border.custom-card--color-black-07 {
  border-left-color: rgba(31, 45, 61, 0.7);
}
.custom-card--side-border.custom-card--color-black-08 {
  border-left-color: rgba(31, 45, 61, 0.8);
}
.custom-card--side-border.custom-card--color-black-09 {
  border-left-color: rgba(31, 45, 61, 0.9);
}
.custom-card--side-border.custom-card--color-white-01 {
  border-left-color: rgba(255, 255, 255, 0.1);
}
.custom-card--side-border.custom-card--color-white-02 {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.custom-card--side-border.custom-card--color-white-03 {
  border-left-color: rgba(255, 255, 255, 0.3);
}
.custom-card--side-border.custom-card--color-white-04 {
  border-left-color: rgba(255, 255, 255, 0.4);
}
.custom-card--side-border.custom-card--color-white-05 {
  border-left-color: rgba(255, 255, 255, 0.5);
}
.custom-card--side-border.custom-card--color-white-06 {
  border-left-color: rgba(255, 255, 255, 0.6);
}
.custom-card--side-border.custom-card--color-white-07 {
  border-left-color: rgba(255, 255, 255, 0.7);
}
.custom-card--side-border.custom-card--color-white-08 {
  border-left-color: rgba(255, 255, 255, 0.8);
}
.custom-card--side-border.custom-card--color-white-09 {
  border-left-color: rgba(255, 255, 255, 0.9);
}
.custom-card--side-border.custom-card--color-primary-01 {
  border-left-color: rgba(30, 183, 255, 0.1);
}
.custom-card--side-border.custom-card--color-primary-02 {
  border-left-color: rgba(30, 183, 255, 0.2);
}
.custom-card--side-border.custom-card--color-primary-03 {
  border-left-color: rgba(30, 183, 255, 0.3);
}
.custom-card--side-border.custom-card--color-primary-04 {
  border-left-color: rgba(30, 183, 255, 0.4);
}
.custom-card--side-border.custom-card--color-primary-05 {
  border-left-color: rgba(30, 183, 255, 0.5);
}
.custom-card--side-border.custom-card--color-primary-06 {
  border-left-color: rgba(30, 183, 255, 0.6);
}
.custom-card--side-border.custom-card--color-primary-07 {
  border-left-color: rgba(30, 183, 255, 0.7);
}
.custom-card--side-border.custom-card--color-primary-08 {
  border-left-color: rgba(30, 183, 255, 0.8);
}
.custom-card--side-border.custom-card--color-primary-09 {
  border-left-color: rgba(30, 183, 255, 0.9);
}
.custom-card--side-border.custom-card--color-info-01 {
  border-left-color: rgba(51, 174, 154, 0.1);
}
.custom-card--side-border.custom-card--color-info-02 {
  border-left-color: rgba(51, 174, 154, 0.2);
}
.custom-card--side-border.custom-card--color-info-03 {
  border-left-color: rgba(51, 174, 154, 0.3);
}
.custom-card--side-border.custom-card--color-info-04 {
  border-left-color: rgba(51, 174, 154, 0.4);
}
.custom-card--side-border.custom-card--color-info-05 {
  border-left-color: rgba(51, 174, 154, 0.5);
}
.custom-card--side-border.custom-card--color-info-06 {
  border-left-color: rgba(51, 174, 154, 0.6);
}
.custom-card--side-border.custom-card--color-info-07 {
  border-left-color: rgba(51, 174, 154, 0.7);
}
.custom-card--side-border.custom-card--color-info-08 {
  border-left-color: rgba(51, 174, 154, 0.8);
}
.custom-card--side-border.custom-card--color-info-09 {
  border-left-color: rgba(51, 174, 154, 0.9);
}
.custom-card--side-border.custom-card--color-success-01 {
  border-left-color: rgba(27, 185, 52, 0.1);
}
.custom-card--side-border.custom-card--color-success-02 {
  border-left-color: rgba(27, 185, 52, 0.2);
}
.custom-card--side-border.custom-card--color-success-03 {
  border-left-color: rgba(27, 185, 52, 0.3);
}
.custom-card--side-border.custom-card--color-success-04 {
  border-left-color: rgba(27, 185, 52, 0.4);
}
.custom-card--side-border.custom-card--color-success-05 {
  border-left-color: rgba(27, 185, 52, 0.5);
}
.custom-card--side-border.custom-card--color-success-06 {
  border-left-color: rgba(27, 185, 52, 0.6);
}
.custom-card--side-border.custom-card--color-success-07 {
  border-left-color: rgba(27, 185, 52, 0.7);
}
.custom-card--side-border.custom-card--color-success-08 {
  border-left-color: rgba(27, 185, 52, 0.8);
}
.custom-card--side-border.custom-card--color-success-09 {
  border-left-color: rgba(27, 185, 52, 0.9);
}
.custom-card--side-border.custom-card--color-warning-01 {
  border-left-color: rgba(242, 114, 18, 0.1);
}
.custom-card--side-border.custom-card--color-warning-02 {
  border-left-color: rgba(242, 114, 18, 0.2);
}
.custom-card--side-border.custom-card--color-warning-03 {
  border-left-color: rgba(242, 114, 18, 0.3);
}
.custom-card--side-border.custom-card--color-warning-04 {
  border-left-color: rgba(242, 114, 18, 0.4);
}
.custom-card--side-border.custom-card--color-warning-05 {
  border-left-color: rgba(242, 114, 18, 0.5);
}
.custom-card--side-border.custom-card--color-warning-06 {
  border-left-color: rgba(242, 114, 18, 0.6);
}
.custom-card--side-border.custom-card--color-warning-07 {
  border-left-color: rgba(242, 114, 18, 0.7);
}
.custom-card--side-border.custom-card--color-warning-08 {
  border-left-color: rgba(242, 114, 18, 0.8);
}
.custom-card--side-border.custom-card--color-warning-09 {
  border-left-color: rgba(242, 114, 18, 0.9);
}
.custom-card--side-border.custom-card--color-danger-01 {
  border-left-color: rgba(237, 28, 36, 0.1);
}
.custom-card--side-border.custom-card--color-danger-02 {
  border-left-color: rgba(237, 28, 36, 0.2);
}
.custom-card--side-border.custom-card--color-danger-03 {
  border-left-color: rgba(237, 28, 36, 0.3);
}
.custom-card--side-border.custom-card--color-danger-04 {
  border-left-color: rgba(237, 28, 36, 0.4);
}
.custom-card--side-border.custom-card--color-danger-05 {
  border-left-color: rgba(237, 28, 36, 0.5);
}
.custom-card--side-border.custom-card--color-danger-06 {
  border-left-color: rgba(237, 28, 36, 0.6);
}
.custom-card--side-border.custom-card--color-danger-07 {
  border-left-color: rgba(237, 28, 36, 0.7);
}
.custom-card--side-border.custom-card--color-danger-08 {
  border-left-color: rgba(237, 28, 36, 0.8);
}
.custom-card--side-border.custom-card--color-danger-09 {
  border-left-color: rgba(237, 28, 36, 0.9);
}
.custom-card--side-border.custom-card--color-yellow-01 {
  border-left-color: rgba(247, 191, 71, 0.1);
}
.custom-card--side-border.custom-card--color-yellow-02 {
  border-left-color: rgba(247, 191, 71, 0.2);
}
.custom-card--side-border.custom-card--color-yellow-03 {
  border-left-color: rgba(247, 191, 71, 0.3);
}
.custom-card--side-border.custom-card--color-yellow-04 {
  border-left-color: rgba(247, 191, 71, 0.4);
}
.custom-card--side-border.custom-card--color-yellow-05 {
  border-left-color: rgba(247, 191, 71, 0.5);
}
.custom-card--side-border.custom-card--color-yellow-06 {
  border-left-color: rgba(247, 191, 71, 0.6);
}
.custom-card--side-border.custom-card--color-yellow-07 {
  border-left-color: rgba(247, 191, 71, 0.7);
}
.custom-card--side-border.custom-card--color-yellow-08 {
  border-left-color: rgba(247, 191, 71, 0.8);
}
.custom-card--side-border.custom-card--color-yellow-09 {
  border-left-color: rgba(247, 191, 71, 0.9);
}
.custom-card--side-border.custom-card--color-secondary-01 {
  border-left-color: rgba(134, 142, 150, 0.1);
}
.custom-card--side-border.custom-card--color-secondary-02 {
  border-left-color: rgba(134, 142, 150, 0.2);
}
.custom-card--side-border.custom-card--color-secondary-03 {
  border-left-color: rgba(134, 142, 150, 0.3);
}
.custom-card--side-border.custom-card--color-secondary-04 {
  border-left-color: rgba(134, 142, 150, 0.4);
}
.custom-card--side-border.custom-card--color-secondary-05 {
  border-left-color: rgba(134, 142, 150, 0.5);
}
.custom-card--side-border.custom-card--color-secondary-06 {
  border-left-color: rgba(134, 142, 150, 0.6);
}
.custom-card--side-border.custom-card--color-secondary-07 {
  border-left-color: rgba(134, 142, 150, 0.7);
}
.custom-card--side-border.custom-card--color-secondary-08 {
  border-left-color: rgba(134, 142, 150, 0.8);
}
.custom-card--side-border.custom-card--color-secondary-09 {
  border-left-color: rgba(134, 142, 150, 0.9);
}
.custom-card--side-border.custom-card--color-dark-01 {
  border-left-color: rgba(63, 70, 81, 0.1);
}
.custom-card--side-border.custom-card--color-dark-02 {
  border-left-color: rgba(63, 70, 81, 0.2);
}
.custom-card--side-border.custom-card--color-dark-03 {
  border-left-color: rgba(63, 70, 81, 0.3);
}
.custom-card--side-border.custom-card--color-dark-04 {
  border-left-color: rgba(63, 70, 81, 0.4);
}
.custom-card--side-border.custom-card--color-dark-05 {
  border-left-color: rgba(63, 70, 81, 0.5);
}
.custom-card--side-border.custom-card--color-dark-06 {
  border-left-color: rgba(63, 70, 81, 0.6);
}
.custom-card--side-border.custom-card--color-dark-07 {
  border-left-color: rgba(63, 70, 81, 0.7);
}
.custom-card--side-border.custom-card--color-dark-08 {
  border-left-color: rgba(63, 70, 81, 0.8);
}
.custom-card--side-border.custom-card--color-dark-09 {
  border-left-color: rgba(63, 70, 81, 0.9);
}
.custom-card--side-border.custom-card--color-light-01 {
  border-left-color: rgba(248, 249, 250, 0.1);
}
.custom-card--side-border.custom-card--color-light-02 {
  border-left-color: rgba(248, 249, 250, 0.2);
}
.custom-card--side-border.custom-card--color-light-03 {
  border-left-color: rgba(248, 249, 250, 0.3);
}
.custom-card--side-border.custom-card--color-light-04 {
  border-left-color: rgba(248, 249, 250, 0.4);
}
.custom-card--side-border.custom-card--color-light-05 {
  border-left-color: rgba(248, 249, 250, 0.5);
}
.custom-card--side-border.custom-card--color-light-06 {
  border-left-color: rgba(248, 249, 250, 0.6);
}
.custom-card--side-border.custom-card--color-light-07 {
  border-left-color: rgba(248, 249, 250, 0.7);
}
.custom-card--side-border.custom-card--color-light-08 {
  border-left-color: rgba(248, 249, 250, 0.8);
}
.custom-card--side-border.custom-card--color-light-09 {
  border-left-color: rgba(248, 249, 250, 0.9);
}
.custom-card--side-border.custom-card--color-purple-01 {
  border-left-color: rgba(202, 142, 255, 0.1);
}
.custom-card--side-border.custom-card--color-purple-02 {
  border-left-color: rgba(202, 142, 255, 0.2);
}
.custom-card--side-border.custom-card--color-purple-03 {
  border-left-color: rgba(202, 142, 255, 0.3);
}
.custom-card--side-border.custom-card--color-purple-04 {
  border-left-color: rgba(202, 142, 255, 0.4);
}
.custom-card--side-border.custom-card--color-purple-05 {
  border-left-color: rgba(202, 142, 255, 0.5);
}
.custom-card--side-border.custom-card--color-purple-06 {
  border-left-color: rgba(202, 142, 255, 0.6);
}
.custom-card--side-border.custom-card--color-purple-07 {
  border-left-color: rgba(202, 142, 255, 0.7);
}
.custom-card--side-border.custom-card--color-purple-08 {
  border-left-color: rgba(202, 142, 255, 0.8);
}
.custom-card--side-border.custom-card--color-purple-09 {
  border-left-color: rgba(202, 142, 255, 0.9);
}
.custom-card--side-border.custom-card--color-primary {
  border-left-color: #1EB7FF;
}
.custom-card--side-border.custom-card--color-secondary {
  border-left-color: #868E96;
}
.custom-card--side-border.custom-card--color-success {
  border-left-color: #1BB934;
}
.custom-card--side-border.custom-card--color-info {
  border-left-color: #33AE9A;
}
.custom-card--side-border.custom-card--color-warning {
  border-left-color: #F27212;
}
.custom-card--side-border.custom-card--color-danger {
  border-left-color: #ED1C24;
}
.custom-card--side-border.custom-card--color-light {
  border-left-color: #F8F9FA;
}
.custom-card--side-border.custom-card--color-dark {
  border-left-color: #3F4651;
}
.custom-card--background {
  color: #FFF;
}
.custom-card--background.custom-card--color-100 {
  background-color: #F8F9FA;
}
.custom-card--background.custom-card--color-200 {
  background-color: #E9ECEF;
}
.custom-card--background.custom-card--color-300 {
  background-color: #DEE2E6;
}
.custom-card--background.custom-card--color-400 {
  background-color: #CED4DA;
}
.custom-card--background.custom-card--color-500 {
  background-color: #ADB5BD;
}
.custom-card--background.custom-card--color-600 {
  background-color: #868E96;
}
.custom-card--background.custom-card--color-700 {
  background-color: #5D636D;
}
.custom-card--background.custom-card--color-800 {
  background-color: #3F4651;
}
.custom-card--background.custom-card--color-900 {
  background-color: #353C48;
}
.custom-card--background.custom-card--color-blue {
  background-color: #1EB7FF;
}
.custom-card--background.custom-card--color-indigo {
  background-color: #6610f2;
}
.custom-card--background.custom-card--color-purple {
  background-color: #CA8EFF;
}
.custom-card--background.custom-card--color-pink {
  background-color: #e83e8c;
}
.custom-card--background.custom-card--color-red {
  background-color: #ED1C24;
}
.custom-card--background.custom-card--color-orange {
  background-color: #F27212;
}
.custom-card--background.custom-card--color-yellow {
  background-color: #F7BF47;
}
.custom-card--background.custom-card--color-green {
  background-color: #1BB934;
}
.custom-card--background.custom-card--color-teal {
  background-color: #20c997;
}
.custom-card--background.custom-card--color-cyan {
  background-color: #33AE9A;
}
.custom-card--background.custom-card--color-white {
  background-color: #FFF;
}
.custom-card--background.custom-card--color-gray {
  background-color: #868E96;
}
.custom-card--background.custom-card--color-gray-dark {
  background-color: #3F4651;
}
.custom-card--background.custom-card--color-facebook {
  background-color: #3A5E95;
}
.custom-card--background.custom-card--color-twitter {
  background-color: #60ADDB;
}
.custom-card--background.custom-card--color-lastfm {
  background-color: #E01132;
}
.custom-card--background.custom-card--color-pinterest {
  background-color: #BD0520;
}
.custom-card--background.custom-card--color-linkedin {
  background-color: #057CB2;
}
.custom-card--background.custom-card--color-medium {
  background-color: #5cb85c;
}
.custom-card--background.custom-card--color-skype {
  background-color: #0AB2EF;
}
.custom-card--background.custom-card--color-foursquare {
  background-color: #F84978;
}
.custom-card--background.custom-card--color-android {
  background-color: #A6C843;
}
.custom-card--background.custom-card--color-spotify {
  background-color: #2FD665;
}
.custom-card--background.custom-card--color-paypal {
  background-color: #2A9AD7;
}
.custom-card--background.custom-card--color-dribble {
  background-color: #F16898;
}
.custom-card--background.custom-card--color-youtube {
  background-color: #ED1922;
}
.custom-card--background.custom-card--color-windows {
  background-color: #10BFF2;
}
.custom-card--background.custom-card--color-amazon {
  background-color: #FEA320;
}
.custom-card--background.custom-card--color-black-01 {
  background-color: rgba(31, 45, 61, 0.1);
}
.custom-card--background.custom-card--color-black-02 {
  background-color: rgba(31, 45, 61, 0.2);
}
.custom-card--background.custom-card--color-black-03 {
  background-color: rgba(31, 45, 61, 0.3);
}
.custom-card--background.custom-card--color-black-04 {
  background-color: rgba(31, 45, 61, 0.4);
}
.custom-card--background.custom-card--color-black-05 {
  background-color: rgba(31, 45, 61, 0.5);
}
.custom-card--background.custom-card--color-black-06 {
  background-color: rgba(31, 45, 61, 0.6);
}
.custom-card--background.custom-card--color-black-07 {
  background-color: rgba(31, 45, 61, 0.7);
}
.custom-card--background.custom-card--color-black-08 {
  background-color: rgba(31, 45, 61, 0.8);
}
.custom-card--background.custom-card--color-black-09 {
  background-color: rgba(31, 45, 61, 0.9);
}
.custom-card--background.custom-card--color-white-01 {
  background-color: rgba(255, 255, 255, 0.1);
}
.custom-card--background.custom-card--color-white-02 {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-card--background.custom-card--color-white-03 {
  background-color: rgba(255, 255, 255, 0.3);
}
.custom-card--background.custom-card--color-white-04 {
  background-color: rgba(255, 255, 255, 0.4);
}
.custom-card--background.custom-card--color-white-05 {
  background-color: rgba(255, 255, 255, 0.5);
}
.custom-card--background.custom-card--color-white-06 {
  background-color: rgba(255, 255, 255, 0.6);
}
.custom-card--background.custom-card--color-white-07 {
  background-color: rgba(255, 255, 255, 0.7);
}
.custom-card--background.custom-card--color-white-08 {
  background-color: rgba(255, 255, 255, 0.8);
}
.custom-card--background.custom-card--color-white-09 {
  background-color: rgba(255, 255, 255, 0.9);
}
.custom-card--background.custom-card--color-primary-01 {
  background-color: rgba(30, 183, 255, 0.1);
}
.custom-card--background.custom-card--color-primary-02 {
  background-color: rgba(30, 183, 255, 0.2);
}
.custom-card--background.custom-card--color-primary-03 {
  background-color: rgba(30, 183, 255, 0.3);
}
.custom-card--background.custom-card--color-primary-04 {
  background-color: rgba(30, 183, 255, 0.4);
}
.custom-card--background.custom-card--color-primary-05 {
  background-color: rgba(30, 183, 255, 0.5);
}
.custom-card--background.custom-card--color-primary-06 {
  background-color: rgba(30, 183, 255, 0.6);
}
.custom-card--background.custom-card--color-primary-07 {
  background-color: rgba(30, 183, 255, 0.7);
}
.custom-card--background.custom-card--color-primary-08 {
  background-color: rgba(30, 183, 255, 0.8);
}
.custom-card--background.custom-card--color-primary-09 {
  background-color: rgba(30, 183, 255, 0.9);
}
.custom-card--background.custom-card--color-info-01 {
  background-color: rgba(51, 174, 154, 0.1);
}
.custom-card--background.custom-card--color-info-02 {
  background-color: rgba(51, 174, 154, 0.2);
}
.custom-card--background.custom-card--color-info-03 {
  background-color: rgba(51, 174, 154, 0.3);
}
.custom-card--background.custom-card--color-info-04 {
  background-color: rgba(51, 174, 154, 0.4);
}
.custom-card--background.custom-card--color-info-05 {
  background-color: rgba(51, 174, 154, 0.5);
}
.custom-card--background.custom-card--color-info-06 {
  background-color: rgba(51, 174, 154, 0.6);
}
.custom-card--background.custom-card--color-info-07 {
  background-color: rgba(51, 174, 154, 0.7);
}
.custom-card--background.custom-card--color-info-08 {
  background-color: rgba(51, 174, 154, 0.8);
}
.custom-card--background.custom-card--color-info-09 {
  background-color: rgba(51, 174, 154, 0.9);
}
.custom-card--background.custom-card--color-success-01 {
  background-color: rgba(27, 185, 52, 0.1);
}
.custom-card--background.custom-card--color-success-02 {
  background-color: rgba(27, 185, 52, 0.2);
}
.custom-card--background.custom-card--color-success-03 {
  background-color: rgba(27, 185, 52, 0.3);
}
.custom-card--background.custom-card--color-success-04 {
  background-color: rgba(27, 185, 52, 0.4);
}
.custom-card--background.custom-card--color-success-05 {
  background-color: rgba(27, 185, 52, 0.5);
}
.custom-card--background.custom-card--color-success-06 {
  background-color: rgba(27, 185, 52, 0.6);
}
.custom-card--background.custom-card--color-success-07 {
  background-color: rgba(27, 185, 52, 0.7);
}
.custom-card--background.custom-card--color-success-08 {
  background-color: rgba(27, 185, 52, 0.8);
}
.custom-card--background.custom-card--color-success-09 {
  background-color: rgba(27, 185, 52, 0.9);
}
.custom-card--background.custom-card--color-warning-01 {
  background-color: rgba(242, 114, 18, 0.1);
}
.custom-card--background.custom-card--color-warning-02 {
  background-color: rgba(242, 114, 18, 0.2);
}
.custom-card--background.custom-card--color-warning-03 {
  background-color: rgba(242, 114, 18, 0.3);
}
.custom-card--background.custom-card--color-warning-04 {
  background-color: rgba(242, 114, 18, 0.4);
}
.custom-card--background.custom-card--color-warning-05 {
  background-color: rgba(242, 114, 18, 0.5);
}
.custom-card--background.custom-card--color-warning-06 {
  background-color: rgba(242, 114, 18, 0.6);
}
.custom-card--background.custom-card--color-warning-07 {
  background-color: rgba(242, 114, 18, 0.7);
}
.custom-card--background.custom-card--color-warning-08 {
  background-color: rgba(242, 114, 18, 0.8);
}
.custom-card--background.custom-card--color-warning-09 {
  background-color: rgba(242, 114, 18, 0.9);
}
.custom-card--background.custom-card--color-danger-01 {
  background-color: rgba(237, 28, 36, 0.1);
}
.custom-card--background.custom-card--color-danger-02 {
  background-color: rgba(237, 28, 36, 0.2);
}
.custom-card--background.custom-card--color-danger-03 {
  background-color: rgba(237, 28, 36, 0.3);
}
.custom-card--background.custom-card--color-danger-04 {
  background-color: rgba(237, 28, 36, 0.4);
}
.custom-card--background.custom-card--color-danger-05 {
  background-color: rgba(237, 28, 36, 0.5);
}
.custom-card--background.custom-card--color-danger-06 {
  background-color: rgba(237, 28, 36, 0.6);
}
.custom-card--background.custom-card--color-danger-07 {
  background-color: rgba(237, 28, 36, 0.7);
}
.custom-card--background.custom-card--color-danger-08 {
  background-color: rgba(237, 28, 36, 0.8);
}
.custom-card--background.custom-card--color-danger-09 {
  background-color: rgba(237, 28, 36, 0.9);
}
.custom-card--background.custom-card--color-yellow-01 {
  background-color: rgba(247, 191, 71, 0.1);
}
.custom-card--background.custom-card--color-yellow-02 {
  background-color: rgba(247, 191, 71, 0.2);
}
.custom-card--background.custom-card--color-yellow-03 {
  background-color: rgba(247, 191, 71, 0.3);
}
.custom-card--background.custom-card--color-yellow-04 {
  background-color: rgba(247, 191, 71, 0.4);
}
.custom-card--background.custom-card--color-yellow-05 {
  background-color: rgba(247, 191, 71, 0.5);
}
.custom-card--background.custom-card--color-yellow-06 {
  background-color: rgba(247, 191, 71, 0.6);
}
.custom-card--background.custom-card--color-yellow-07 {
  background-color: rgba(247, 191, 71, 0.7);
}
.custom-card--background.custom-card--color-yellow-08 {
  background-color: rgba(247, 191, 71, 0.8);
}
.custom-card--background.custom-card--color-yellow-09 {
  background-color: rgba(247, 191, 71, 0.9);
}
.custom-card--background.custom-card--color-secondary-01 {
  background-color: rgba(134, 142, 150, 0.1);
}
.custom-card--background.custom-card--color-secondary-02 {
  background-color: rgba(134, 142, 150, 0.2);
}
.custom-card--background.custom-card--color-secondary-03 {
  background-color: rgba(134, 142, 150, 0.3);
}
.custom-card--background.custom-card--color-secondary-04 {
  background-color: rgba(134, 142, 150, 0.4);
}
.custom-card--background.custom-card--color-secondary-05 {
  background-color: rgba(134, 142, 150, 0.5);
}
.custom-card--background.custom-card--color-secondary-06 {
  background-color: rgba(134, 142, 150, 0.6);
}
.custom-card--background.custom-card--color-secondary-07 {
  background-color: rgba(134, 142, 150, 0.7);
}
.custom-card--background.custom-card--color-secondary-08 {
  background-color: rgba(134, 142, 150, 0.8);
}
.custom-card--background.custom-card--color-secondary-09 {
  background-color: rgba(134, 142, 150, 0.9);
}
.custom-card--background.custom-card--color-dark-01 {
  background-color: rgba(63, 70, 81, 0.1);
}
.custom-card--background.custom-card--color-dark-02 {
  background-color: rgba(63, 70, 81, 0.2);
}
.custom-card--background.custom-card--color-dark-03 {
  background-color: rgba(63, 70, 81, 0.3);
}
.custom-card--background.custom-card--color-dark-04 {
  background-color: rgba(63, 70, 81, 0.4);
}
.custom-card--background.custom-card--color-dark-05 {
  background-color: rgba(63, 70, 81, 0.5);
}
.custom-card--background.custom-card--color-dark-06 {
  background-color: rgba(63, 70, 81, 0.6);
}
.custom-card--background.custom-card--color-dark-07 {
  background-color: rgba(63, 70, 81, 0.7);
}
.custom-card--background.custom-card--color-dark-08 {
  background-color: rgba(63, 70, 81, 0.8);
}
.custom-card--background.custom-card--color-dark-09 {
  background-color: rgba(63, 70, 81, 0.9);
}
.custom-card--background.custom-card--color-light-01 {
  background-color: rgba(248, 249, 250, 0.1);
}
.custom-card--background.custom-card--color-light-02 {
  background-color: rgba(248, 249, 250, 0.2);
}
.custom-card--background.custom-card--color-light-03 {
  background-color: rgba(248, 249, 250, 0.3);
}
.custom-card--background.custom-card--color-light-04 {
  background-color: rgba(248, 249, 250, 0.4);
}
.custom-card--background.custom-card--color-light-05 {
  background-color: rgba(248, 249, 250, 0.5);
}
.custom-card--background.custom-card--color-light-06 {
  background-color: rgba(248, 249, 250, 0.6);
}
.custom-card--background.custom-card--color-light-07 {
  background-color: rgba(248, 249, 250, 0.7);
}
.custom-card--background.custom-card--color-light-08 {
  background-color: rgba(248, 249, 250, 0.8);
}
.custom-card--background.custom-card--color-light-09 {
  background-color: rgba(248, 249, 250, 0.9);
}
.custom-card--background.custom-card--color-purple-01 {
  background-color: rgba(202, 142, 255, 0.1);
}
.custom-card--background.custom-card--color-purple-02 {
  background-color: rgba(202, 142, 255, 0.2);
}
.custom-card--background.custom-card--color-purple-03 {
  background-color: rgba(202, 142, 255, 0.3);
}
.custom-card--background.custom-card--color-purple-04 {
  background-color: rgba(202, 142, 255, 0.4);
}
.custom-card--background.custom-card--color-purple-05 {
  background-color: rgba(202, 142, 255, 0.5);
}
.custom-card--background.custom-card--color-purple-06 {
  background-color: rgba(202, 142, 255, 0.6);
}
.custom-card--background.custom-card--color-purple-07 {
  background-color: rgba(202, 142, 255, 0.7);
}
.custom-card--background.custom-card--color-purple-08 {
  background-color: rgba(202, 142, 255, 0.8);
}
.custom-card--background.custom-card--color-purple-09 {
  background-color: rgba(202, 142, 255, 0.9);
}
.custom-card--background.custom-card--color-primary {
  background-color: #1EB7FF;
}
.custom-card--background.custom-card--color-secondary {
  background-color: #868E96;
}
.custom-card--background.custom-card--color-success {
  background-color: #1BB934;
}
.custom-card--background.custom-card--color-info {
  background-color: #33AE9A;
}
.custom-card--background.custom-card--color-warning {
  background-color: #F27212;
}
.custom-card--background.custom-card--color-danger {
  background-color: #ED1C24;
}
.custom-card--background.custom-card--color-light {
  background-color: #F8F9FA;
}
.custom-card--background.custom-card--color-dark {
  background-color: #3F4651;
}
.custom-card--border-dash {
  border-style: dashed;
  border-width: 2px;
}
.custom-card--border-dot {
  border-style: dotted;
  border-width: 2px;
}
.custom-card--shadow {
  box-shadow: 0 1px 2px 0 rgba(31, 45, 61, 0.07);
}
.custom-card--none {
  border: none;
  background: none;
}

/* Avatar Variants Definitions */
.custom-card-header--background {
  color: #FFF;
}
.custom-card-header--background.custom-card-header--color-100 {
  background-color: #F8F9FA;
}
.custom-card-header--background.custom-card-header--color-200 {
  background-color: #E9ECEF;
}
.custom-card-header--background.custom-card-header--color-300 {
  background-color: #DEE2E6;
}
.custom-card-header--background.custom-card-header--color-400 {
  background-color: #CED4DA;
}
.custom-card-header--background.custom-card-header--color-500 {
  background-color: #ADB5BD;
}
.custom-card-header--background.custom-card-header--color-600 {
  background-color: #868E96;
}
.custom-card-header--background.custom-card-header--color-700 {
  background-color: #5D636D;
}
.custom-card-header--background.custom-card-header--color-800 {
  background-color: #3F4651;
}
.custom-card-header--background.custom-card-header--color-900 {
  background-color: #353C48;
}
.custom-card-header--background.custom-card-header--color-blue {
  background-color: #1EB7FF;
}
.custom-card-header--background.custom-card-header--color-indigo {
  background-color: #6610f2;
}
.custom-card-header--background.custom-card-header--color-purple {
  background-color: #CA8EFF;
}
.custom-card-header--background.custom-card-header--color-pink {
  background-color: #e83e8c;
}
.custom-card-header--background.custom-card-header--color-red {
  background-color: #ED1C24;
}
.custom-card-header--background.custom-card-header--color-orange {
  background-color: #F27212;
}
.custom-card-header--background.custom-card-header--color-yellow {
  background-color: #F7BF47;
}
.custom-card-header--background.custom-card-header--color-green {
  background-color: #1BB934;
}
.custom-card-header--background.custom-card-header--color-teal {
  background-color: #20c997;
}
.custom-card-header--background.custom-card-header--color-cyan {
  background-color: #33AE9A;
}
.custom-card-header--background.custom-card-header--color-white {
  background-color: #FFF;
}
.custom-card-header--background.custom-card-header--color-gray {
  background-color: #868E96;
}
.custom-card-header--background.custom-card-header--color-gray-dark {
  background-color: #3F4651;
}
.custom-card-header--background.custom-card-header--color-facebook {
  background-color: #3A5E95;
}
.custom-card-header--background.custom-card-header--color-twitter {
  background-color: #60ADDB;
}
.custom-card-header--background.custom-card-header--color-lastfm {
  background-color: #E01132;
}
.custom-card-header--background.custom-card-header--color-pinterest {
  background-color: #BD0520;
}
.custom-card-header--background.custom-card-header--color-linkedin {
  background-color: #057CB2;
}
.custom-card-header--background.custom-card-header--color-medium {
  background-color: #5cb85c;
}
.custom-card-header--background.custom-card-header--color-skype {
  background-color: #0AB2EF;
}
.custom-card-header--background.custom-card-header--color-foursquare {
  background-color: #F84978;
}
.custom-card-header--background.custom-card-header--color-android {
  background-color: #A6C843;
}
.custom-card-header--background.custom-card-header--color-spotify {
  background-color: #2FD665;
}
.custom-card-header--background.custom-card-header--color-paypal {
  background-color: #2A9AD7;
}
.custom-card-header--background.custom-card-header--color-dribble {
  background-color: #F16898;
}
.custom-card-header--background.custom-card-header--color-youtube {
  background-color: #ED1922;
}
.custom-card-header--background.custom-card-header--color-windows {
  background-color: #10BFF2;
}
.custom-card-header--background.custom-card-header--color-amazon {
  background-color: #FEA320;
}
.custom-card-header--background.custom-card-header--color-black-01 {
  background-color: rgba(31, 45, 61, 0.1);
}
.custom-card-header--background.custom-card-header--color-black-02 {
  background-color: rgba(31, 45, 61, 0.2);
}
.custom-card-header--background.custom-card-header--color-black-03 {
  background-color: rgba(31, 45, 61, 0.3);
}
.custom-card-header--background.custom-card-header--color-black-04 {
  background-color: rgba(31, 45, 61, 0.4);
}
.custom-card-header--background.custom-card-header--color-black-05 {
  background-color: rgba(31, 45, 61, 0.5);
}
.custom-card-header--background.custom-card-header--color-black-06 {
  background-color: rgba(31, 45, 61, 0.6);
}
.custom-card-header--background.custom-card-header--color-black-07 {
  background-color: rgba(31, 45, 61, 0.7);
}
.custom-card-header--background.custom-card-header--color-black-08 {
  background-color: rgba(31, 45, 61, 0.8);
}
.custom-card-header--background.custom-card-header--color-black-09 {
  background-color: rgba(31, 45, 61, 0.9);
}
.custom-card-header--background.custom-card-header--color-white-01 {
  background-color: rgba(255, 255, 255, 0.1);
}
.custom-card-header--background.custom-card-header--color-white-02 {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-card-header--background.custom-card-header--color-white-03 {
  background-color: rgba(255, 255, 255, 0.3);
}
.custom-card-header--background.custom-card-header--color-white-04 {
  background-color: rgba(255, 255, 255, 0.4);
}
.custom-card-header--background.custom-card-header--color-white-05 {
  background-color: rgba(255, 255, 255, 0.5);
}
.custom-card-header--background.custom-card-header--color-white-06 {
  background-color: rgba(255, 255, 255, 0.6);
}
.custom-card-header--background.custom-card-header--color-white-07 {
  background-color: rgba(255, 255, 255, 0.7);
}
.custom-card-header--background.custom-card-header--color-white-08 {
  background-color: rgba(255, 255, 255, 0.8);
}
.custom-card-header--background.custom-card-header--color-white-09 {
  background-color: rgba(255, 255, 255, 0.9);
}
.custom-card-header--background.custom-card-header--color-primary-01 {
  background-color: rgba(30, 183, 255, 0.1);
}
.custom-card-header--background.custom-card-header--color-primary-02 {
  background-color: rgba(30, 183, 255, 0.2);
}
.custom-card-header--background.custom-card-header--color-primary-03 {
  background-color: rgba(30, 183, 255, 0.3);
}
.custom-card-header--background.custom-card-header--color-primary-04 {
  background-color: rgba(30, 183, 255, 0.4);
}
.custom-card-header--background.custom-card-header--color-primary-05 {
  background-color: rgba(30, 183, 255, 0.5);
}
.custom-card-header--background.custom-card-header--color-primary-06 {
  background-color: rgba(30, 183, 255, 0.6);
}
.custom-card-header--background.custom-card-header--color-primary-07 {
  background-color: rgba(30, 183, 255, 0.7);
}
.custom-card-header--background.custom-card-header--color-primary-08 {
  background-color: rgba(30, 183, 255, 0.8);
}
.custom-card-header--background.custom-card-header--color-primary-09 {
  background-color: rgba(30, 183, 255, 0.9);
}
.custom-card-header--background.custom-card-header--color-info-01 {
  background-color: rgba(51, 174, 154, 0.1);
}
.custom-card-header--background.custom-card-header--color-info-02 {
  background-color: rgba(51, 174, 154, 0.2);
}
.custom-card-header--background.custom-card-header--color-info-03 {
  background-color: rgba(51, 174, 154, 0.3);
}
.custom-card-header--background.custom-card-header--color-info-04 {
  background-color: rgba(51, 174, 154, 0.4);
}
.custom-card-header--background.custom-card-header--color-info-05 {
  background-color: rgba(51, 174, 154, 0.5);
}
.custom-card-header--background.custom-card-header--color-info-06 {
  background-color: rgba(51, 174, 154, 0.6);
}
.custom-card-header--background.custom-card-header--color-info-07 {
  background-color: rgba(51, 174, 154, 0.7);
}
.custom-card-header--background.custom-card-header--color-info-08 {
  background-color: rgba(51, 174, 154, 0.8);
}
.custom-card-header--background.custom-card-header--color-info-09 {
  background-color: rgba(51, 174, 154, 0.9);
}
.custom-card-header--background.custom-card-header--color-success-01 {
  background-color: rgba(27, 185, 52, 0.1);
}
.custom-card-header--background.custom-card-header--color-success-02 {
  background-color: rgba(27, 185, 52, 0.2);
}
.custom-card-header--background.custom-card-header--color-success-03 {
  background-color: rgba(27, 185, 52, 0.3);
}
.custom-card-header--background.custom-card-header--color-success-04 {
  background-color: rgba(27, 185, 52, 0.4);
}
.custom-card-header--background.custom-card-header--color-success-05 {
  background-color: rgba(27, 185, 52, 0.5);
}
.custom-card-header--background.custom-card-header--color-success-06 {
  background-color: rgba(27, 185, 52, 0.6);
}
.custom-card-header--background.custom-card-header--color-success-07 {
  background-color: rgba(27, 185, 52, 0.7);
}
.custom-card-header--background.custom-card-header--color-success-08 {
  background-color: rgba(27, 185, 52, 0.8);
}
.custom-card-header--background.custom-card-header--color-success-09 {
  background-color: rgba(27, 185, 52, 0.9);
}
.custom-card-header--background.custom-card-header--color-warning-01 {
  background-color: rgba(242, 114, 18, 0.1);
}
.custom-card-header--background.custom-card-header--color-warning-02 {
  background-color: rgba(242, 114, 18, 0.2);
}
.custom-card-header--background.custom-card-header--color-warning-03 {
  background-color: rgba(242, 114, 18, 0.3);
}
.custom-card-header--background.custom-card-header--color-warning-04 {
  background-color: rgba(242, 114, 18, 0.4);
}
.custom-card-header--background.custom-card-header--color-warning-05 {
  background-color: rgba(242, 114, 18, 0.5);
}
.custom-card-header--background.custom-card-header--color-warning-06 {
  background-color: rgba(242, 114, 18, 0.6);
}
.custom-card-header--background.custom-card-header--color-warning-07 {
  background-color: rgba(242, 114, 18, 0.7);
}
.custom-card-header--background.custom-card-header--color-warning-08 {
  background-color: rgba(242, 114, 18, 0.8);
}
.custom-card-header--background.custom-card-header--color-warning-09 {
  background-color: rgba(242, 114, 18, 0.9);
}
.custom-card-header--background.custom-card-header--color-danger-01 {
  background-color: rgba(237, 28, 36, 0.1);
}
.custom-card-header--background.custom-card-header--color-danger-02 {
  background-color: rgba(237, 28, 36, 0.2);
}
.custom-card-header--background.custom-card-header--color-danger-03 {
  background-color: rgba(237, 28, 36, 0.3);
}
.custom-card-header--background.custom-card-header--color-danger-04 {
  background-color: rgba(237, 28, 36, 0.4);
}
.custom-card-header--background.custom-card-header--color-danger-05 {
  background-color: rgba(237, 28, 36, 0.5);
}
.custom-card-header--background.custom-card-header--color-danger-06 {
  background-color: rgba(237, 28, 36, 0.6);
}
.custom-card-header--background.custom-card-header--color-danger-07 {
  background-color: rgba(237, 28, 36, 0.7);
}
.custom-card-header--background.custom-card-header--color-danger-08 {
  background-color: rgba(237, 28, 36, 0.8);
}
.custom-card-header--background.custom-card-header--color-danger-09 {
  background-color: rgba(237, 28, 36, 0.9);
}
.custom-card-header--background.custom-card-header--color-yellow-01 {
  background-color: rgba(247, 191, 71, 0.1);
}
.custom-card-header--background.custom-card-header--color-yellow-02 {
  background-color: rgba(247, 191, 71, 0.2);
}
.custom-card-header--background.custom-card-header--color-yellow-03 {
  background-color: rgba(247, 191, 71, 0.3);
}
.custom-card-header--background.custom-card-header--color-yellow-04 {
  background-color: rgba(247, 191, 71, 0.4);
}
.custom-card-header--background.custom-card-header--color-yellow-05 {
  background-color: rgba(247, 191, 71, 0.5);
}
.custom-card-header--background.custom-card-header--color-yellow-06 {
  background-color: rgba(247, 191, 71, 0.6);
}
.custom-card-header--background.custom-card-header--color-yellow-07 {
  background-color: rgba(247, 191, 71, 0.7);
}
.custom-card-header--background.custom-card-header--color-yellow-08 {
  background-color: rgba(247, 191, 71, 0.8);
}
.custom-card-header--background.custom-card-header--color-yellow-09 {
  background-color: rgba(247, 191, 71, 0.9);
}
.custom-card-header--background.custom-card-header--color-secondary-01 {
  background-color: rgba(134, 142, 150, 0.1);
}
.custom-card-header--background.custom-card-header--color-secondary-02 {
  background-color: rgba(134, 142, 150, 0.2);
}
.custom-card-header--background.custom-card-header--color-secondary-03 {
  background-color: rgba(134, 142, 150, 0.3);
}
.custom-card-header--background.custom-card-header--color-secondary-04 {
  background-color: rgba(134, 142, 150, 0.4);
}
.custom-card-header--background.custom-card-header--color-secondary-05 {
  background-color: rgba(134, 142, 150, 0.5);
}
.custom-card-header--background.custom-card-header--color-secondary-06 {
  background-color: rgba(134, 142, 150, 0.6);
}
.custom-card-header--background.custom-card-header--color-secondary-07 {
  background-color: rgba(134, 142, 150, 0.7);
}
.custom-card-header--background.custom-card-header--color-secondary-08 {
  background-color: rgba(134, 142, 150, 0.8);
}
.custom-card-header--background.custom-card-header--color-secondary-09 {
  background-color: rgba(134, 142, 150, 0.9);
}
.custom-card-header--background.custom-card-header--color-dark-01 {
  background-color: rgba(63, 70, 81, 0.1);
}
.custom-card-header--background.custom-card-header--color-dark-02 {
  background-color: rgba(63, 70, 81, 0.2);
}
.custom-card-header--background.custom-card-header--color-dark-03 {
  background-color: rgba(63, 70, 81, 0.3);
}
.custom-card-header--background.custom-card-header--color-dark-04 {
  background-color: rgba(63, 70, 81, 0.4);
}
.custom-card-header--background.custom-card-header--color-dark-05 {
  background-color: rgba(63, 70, 81, 0.5);
}
.custom-card-header--background.custom-card-header--color-dark-06 {
  background-color: rgba(63, 70, 81, 0.6);
}
.custom-card-header--background.custom-card-header--color-dark-07 {
  background-color: rgba(63, 70, 81, 0.7);
}
.custom-card-header--background.custom-card-header--color-dark-08 {
  background-color: rgba(63, 70, 81, 0.8);
}
.custom-card-header--background.custom-card-header--color-dark-09 {
  background-color: rgba(63, 70, 81, 0.9);
}
.custom-card-header--background.custom-card-header--color-light-01 {
  background-color: rgba(248, 249, 250, 0.1);
}
.custom-card-header--background.custom-card-header--color-light-02 {
  background-color: rgba(248, 249, 250, 0.2);
}
.custom-card-header--background.custom-card-header--color-light-03 {
  background-color: rgba(248, 249, 250, 0.3);
}
.custom-card-header--background.custom-card-header--color-light-04 {
  background-color: rgba(248, 249, 250, 0.4);
}
.custom-card-header--background.custom-card-header--color-light-05 {
  background-color: rgba(248, 249, 250, 0.5);
}
.custom-card-header--background.custom-card-header--color-light-06 {
  background-color: rgba(248, 249, 250, 0.6);
}
.custom-card-header--background.custom-card-header--color-light-07 {
  background-color: rgba(248, 249, 250, 0.7);
}
.custom-card-header--background.custom-card-header--color-light-08 {
  background-color: rgba(248, 249, 250, 0.8);
}
.custom-card-header--background.custom-card-header--color-light-09 {
  background-color: rgba(248, 249, 250, 0.9);
}
.custom-card-header--background.custom-card-header--color-purple-01 {
  background-color: rgba(202, 142, 255, 0.1);
}
.custom-card-header--background.custom-card-header--color-purple-02 {
  background-color: rgba(202, 142, 255, 0.2);
}
.custom-card-header--background.custom-card-header--color-purple-03 {
  background-color: rgba(202, 142, 255, 0.3);
}
.custom-card-header--background.custom-card-header--color-purple-04 {
  background-color: rgba(202, 142, 255, 0.4);
}
.custom-card-header--background.custom-card-header--color-purple-05 {
  background-color: rgba(202, 142, 255, 0.5);
}
.custom-card-header--background.custom-card-header--color-purple-06 {
  background-color: rgba(202, 142, 255, 0.6);
}
.custom-card-header--background.custom-card-header--color-purple-07 {
  background-color: rgba(202, 142, 255, 0.7);
}
.custom-card-header--background.custom-card-header--color-purple-08 {
  background-color: rgba(202, 142, 255, 0.8);
}
.custom-card-header--background.custom-card-header--color-purple-09 {
  background-color: rgba(202, 142, 255, 0.9);
}
.custom-card-header--background.custom-card-header--color-primary {
  background-color: #1EB7FF;
}
.custom-card-header--background.custom-card-header--color-secondary {
  background-color: #868E96;
}
.custom-card-header--background.custom-card-header--color-success {
  background-color: #1BB934;
}
.custom-card-header--background.custom-card-header--color-info {
  background-color: #33AE9A;
}
.custom-card-header--background.custom-card-header--color-warning {
  background-color: #F27212;
}
.custom-card-header--background.custom-card-header--color-danger {
  background-color: #ED1C24;
}
.custom-card-header--background.custom-card-header--color-light {
  background-color: #F8F9FA;
}
.custom-card-header--background.custom-card-header--color-dark {
  background-color: #3F4651;
}
.custom-card-header--border {
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
  border-bottom: 2px solid;
  background: none;
}
.custom-card-header--border.custom-card-header--color-100 {
  border-bottom-color: #F8F9FA;
}
.custom-card-header--border.custom-card-header--color-200 {
  border-bottom-color: #E9ECEF;
}
.custom-card-header--border.custom-card-header--color-300 {
  border-bottom-color: #DEE2E6;
}
.custom-card-header--border.custom-card-header--color-400 {
  border-bottom-color: #CED4DA;
}
.custom-card-header--border.custom-card-header--color-500 {
  border-bottom-color: #ADB5BD;
}
.custom-card-header--border.custom-card-header--color-600 {
  border-bottom-color: #868E96;
}
.custom-card-header--border.custom-card-header--color-700 {
  border-bottom-color: #5D636D;
}
.custom-card-header--border.custom-card-header--color-800 {
  border-bottom-color: #3F4651;
}
.custom-card-header--border.custom-card-header--color-900 {
  border-bottom-color: #353C48;
}
.custom-card-header--border.custom-card-header--color-blue {
  border-bottom-color: #1EB7FF;
}
.custom-card-header--border.custom-card-header--color-indigo {
  border-bottom-color: #6610f2;
}
.custom-card-header--border.custom-card-header--color-purple {
  border-bottom-color: #CA8EFF;
}
.custom-card-header--border.custom-card-header--color-pink {
  border-bottom-color: #e83e8c;
}
.custom-card-header--border.custom-card-header--color-red {
  border-bottom-color: #ED1C24;
}
.custom-card-header--border.custom-card-header--color-orange {
  border-bottom-color: #F27212;
}
.custom-card-header--border.custom-card-header--color-yellow {
  border-bottom-color: #F7BF47;
}
.custom-card-header--border.custom-card-header--color-green {
  border-bottom-color: #1BB934;
}
.custom-card-header--border.custom-card-header--color-teal {
  border-bottom-color: #20c997;
}
.custom-card-header--border.custom-card-header--color-cyan {
  border-bottom-color: #33AE9A;
}
.custom-card-header--border.custom-card-header--color-white {
  border-bottom-color: #FFF;
}
.custom-card-header--border.custom-card-header--color-gray {
  border-bottom-color: #868E96;
}
.custom-card-header--border.custom-card-header--color-gray-dark {
  border-bottom-color: #3F4651;
}
.custom-card-header--border.custom-card-header--color-facebook {
  border-bottom-color: #3A5E95;
}
.custom-card-header--border.custom-card-header--color-twitter {
  border-bottom-color: #60ADDB;
}
.custom-card-header--border.custom-card-header--color-lastfm {
  border-bottom-color: #E01132;
}
.custom-card-header--border.custom-card-header--color-pinterest {
  border-bottom-color: #BD0520;
}
.custom-card-header--border.custom-card-header--color-linkedin {
  border-bottom-color: #057CB2;
}
.custom-card-header--border.custom-card-header--color-medium {
  border-bottom-color: #5cb85c;
}
.custom-card-header--border.custom-card-header--color-skype {
  border-bottom-color: #0AB2EF;
}
.custom-card-header--border.custom-card-header--color-foursquare {
  border-bottom-color: #F84978;
}
.custom-card-header--border.custom-card-header--color-android {
  border-bottom-color: #A6C843;
}
.custom-card-header--border.custom-card-header--color-spotify {
  border-bottom-color: #2FD665;
}
.custom-card-header--border.custom-card-header--color-paypal {
  border-bottom-color: #2A9AD7;
}
.custom-card-header--border.custom-card-header--color-dribble {
  border-bottom-color: #F16898;
}
.custom-card-header--border.custom-card-header--color-youtube {
  border-bottom-color: #ED1922;
}
.custom-card-header--border.custom-card-header--color-windows {
  border-bottom-color: #10BFF2;
}
.custom-card-header--border.custom-card-header--color-amazon {
  border-bottom-color: #FEA320;
}
.custom-card-header--border.custom-card-header--color-black-01 {
  border-bottom-color: rgba(31, 45, 61, 0.1);
}
.custom-card-header--border.custom-card-header--color-black-02 {
  border-bottom-color: rgba(31, 45, 61, 0.2);
}
.custom-card-header--border.custom-card-header--color-black-03 {
  border-bottom-color: rgba(31, 45, 61, 0.3);
}
.custom-card-header--border.custom-card-header--color-black-04 {
  border-bottom-color: rgba(31, 45, 61, 0.4);
}
.custom-card-header--border.custom-card-header--color-black-05 {
  border-bottom-color: rgba(31, 45, 61, 0.5);
}
.custom-card-header--border.custom-card-header--color-black-06 {
  border-bottom-color: rgba(31, 45, 61, 0.6);
}
.custom-card-header--border.custom-card-header--color-black-07 {
  border-bottom-color: rgba(31, 45, 61, 0.7);
}
.custom-card-header--border.custom-card-header--color-black-08 {
  border-bottom-color: rgba(31, 45, 61, 0.8);
}
.custom-card-header--border.custom-card-header--color-black-09 {
  border-bottom-color: rgba(31, 45, 61, 0.9);
}
.custom-card-header--border.custom-card-header--color-white-01 {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.custom-card-header--border.custom-card-header--color-white-02 {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.custom-card-header--border.custom-card-header--color-white-03 {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.custom-card-header--border.custom-card-header--color-white-04 {
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
.custom-card-header--border.custom-card-header--color-white-05 {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.custom-card-header--border.custom-card-header--color-white-06 {
  border-bottom-color: rgba(255, 255, 255, 0.6);
}
.custom-card-header--border.custom-card-header--color-white-07 {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}
.custom-card-header--border.custom-card-header--color-white-08 {
  border-bottom-color: rgba(255, 255, 255, 0.8);
}
.custom-card-header--border.custom-card-header--color-white-09 {
  border-bottom-color: rgba(255, 255, 255, 0.9);
}
.custom-card-header--border.custom-card-header--color-primary-01 {
  border-bottom-color: rgba(30, 183, 255, 0.1);
}
.custom-card-header--border.custom-card-header--color-primary-02 {
  border-bottom-color: rgba(30, 183, 255, 0.2);
}
.custom-card-header--border.custom-card-header--color-primary-03 {
  border-bottom-color: rgba(30, 183, 255, 0.3);
}
.custom-card-header--border.custom-card-header--color-primary-04 {
  border-bottom-color: rgba(30, 183, 255, 0.4);
}
.custom-card-header--border.custom-card-header--color-primary-05 {
  border-bottom-color: rgba(30, 183, 255, 0.5);
}
.custom-card-header--border.custom-card-header--color-primary-06 {
  border-bottom-color: rgba(30, 183, 255, 0.6);
}
.custom-card-header--border.custom-card-header--color-primary-07 {
  border-bottom-color: rgba(30, 183, 255, 0.7);
}
.custom-card-header--border.custom-card-header--color-primary-08 {
  border-bottom-color: rgba(30, 183, 255, 0.8);
}
.custom-card-header--border.custom-card-header--color-primary-09 {
  border-bottom-color: rgba(30, 183, 255, 0.9);
}
.custom-card-header--border.custom-card-header--color-info-01 {
  border-bottom-color: rgba(51, 174, 154, 0.1);
}
.custom-card-header--border.custom-card-header--color-info-02 {
  border-bottom-color: rgba(51, 174, 154, 0.2);
}
.custom-card-header--border.custom-card-header--color-info-03 {
  border-bottom-color: rgba(51, 174, 154, 0.3);
}
.custom-card-header--border.custom-card-header--color-info-04 {
  border-bottom-color: rgba(51, 174, 154, 0.4);
}
.custom-card-header--border.custom-card-header--color-info-05 {
  border-bottom-color: rgba(51, 174, 154, 0.5);
}
.custom-card-header--border.custom-card-header--color-info-06 {
  border-bottom-color: rgba(51, 174, 154, 0.6);
}
.custom-card-header--border.custom-card-header--color-info-07 {
  border-bottom-color: rgba(51, 174, 154, 0.7);
}
.custom-card-header--border.custom-card-header--color-info-08 {
  border-bottom-color: rgba(51, 174, 154, 0.8);
}
.custom-card-header--border.custom-card-header--color-info-09 {
  border-bottom-color: rgba(51, 174, 154, 0.9);
}
.custom-card-header--border.custom-card-header--color-success-01 {
  border-bottom-color: rgba(27, 185, 52, 0.1);
}
.custom-card-header--border.custom-card-header--color-success-02 {
  border-bottom-color: rgba(27, 185, 52, 0.2);
}
.custom-card-header--border.custom-card-header--color-success-03 {
  border-bottom-color: rgba(27, 185, 52, 0.3);
}
.custom-card-header--border.custom-card-header--color-success-04 {
  border-bottom-color: rgba(27, 185, 52, 0.4);
}
.custom-card-header--border.custom-card-header--color-success-05 {
  border-bottom-color: rgba(27, 185, 52, 0.5);
}
.custom-card-header--border.custom-card-header--color-success-06 {
  border-bottom-color: rgba(27, 185, 52, 0.6);
}
.custom-card-header--border.custom-card-header--color-success-07 {
  border-bottom-color: rgba(27, 185, 52, 0.7);
}
.custom-card-header--border.custom-card-header--color-success-08 {
  border-bottom-color: rgba(27, 185, 52, 0.8);
}
.custom-card-header--border.custom-card-header--color-success-09 {
  border-bottom-color: rgba(27, 185, 52, 0.9);
}
.custom-card-header--border.custom-card-header--color-warning-01 {
  border-bottom-color: rgba(242, 114, 18, 0.1);
}
.custom-card-header--border.custom-card-header--color-warning-02 {
  border-bottom-color: rgba(242, 114, 18, 0.2);
}
.custom-card-header--border.custom-card-header--color-warning-03 {
  border-bottom-color: rgba(242, 114, 18, 0.3);
}
.custom-card-header--border.custom-card-header--color-warning-04 {
  border-bottom-color: rgba(242, 114, 18, 0.4);
}
.custom-card-header--border.custom-card-header--color-warning-05 {
  border-bottom-color: rgba(242, 114, 18, 0.5);
}
.custom-card-header--border.custom-card-header--color-warning-06 {
  border-bottom-color: rgba(242, 114, 18, 0.6);
}
.custom-card-header--border.custom-card-header--color-warning-07 {
  border-bottom-color: rgba(242, 114, 18, 0.7);
}
.custom-card-header--border.custom-card-header--color-warning-08 {
  border-bottom-color: rgba(242, 114, 18, 0.8);
}
.custom-card-header--border.custom-card-header--color-warning-09 {
  border-bottom-color: rgba(242, 114, 18, 0.9);
}
.custom-card-header--border.custom-card-header--color-danger-01 {
  border-bottom-color: rgba(237, 28, 36, 0.1);
}
.custom-card-header--border.custom-card-header--color-danger-02 {
  border-bottom-color: rgba(237, 28, 36, 0.2);
}
.custom-card-header--border.custom-card-header--color-danger-03 {
  border-bottom-color: rgba(237, 28, 36, 0.3);
}
.custom-card-header--border.custom-card-header--color-danger-04 {
  border-bottom-color: rgba(237, 28, 36, 0.4);
}
.custom-card-header--border.custom-card-header--color-danger-05 {
  border-bottom-color: rgba(237, 28, 36, 0.5);
}
.custom-card-header--border.custom-card-header--color-danger-06 {
  border-bottom-color: rgba(237, 28, 36, 0.6);
}
.custom-card-header--border.custom-card-header--color-danger-07 {
  border-bottom-color: rgba(237, 28, 36, 0.7);
}
.custom-card-header--border.custom-card-header--color-danger-08 {
  border-bottom-color: rgba(237, 28, 36, 0.8);
}
.custom-card-header--border.custom-card-header--color-danger-09 {
  border-bottom-color: rgba(237, 28, 36, 0.9);
}
.custom-card-header--border.custom-card-header--color-yellow-01 {
  border-bottom-color: rgba(247, 191, 71, 0.1);
}
.custom-card-header--border.custom-card-header--color-yellow-02 {
  border-bottom-color: rgba(247, 191, 71, 0.2);
}
.custom-card-header--border.custom-card-header--color-yellow-03 {
  border-bottom-color: rgba(247, 191, 71, 0.3);
}
.custom-card-header--border.custom-card-header--color-yellow-04 {
  border-bottom-color: rgba(247, 191, 71, 0.4);
}
.custom-card-header--border.custom-card-header--color-yellow-05 {
  border-bottom-color: rgba(247, 191, 71, 0.5);
}
.custom-card-header--border.custom-card-header--color-yellow-06 {
  border-bottom-color: rgba(247, 191, 71, 0.6);
}
.custom-card-header--border.custom-card-header--color-yellow-07 {
  border-bottom-color: rgba(247, 191, 71, 0.7);
}
.custom-card-header--border.custom-card-header--color-yellow-08 {
  border-bottom-color: rgba(247, 191, 71, 0.8);
}
.custom-card-header--border.custom-card-header--color-yellow-09 {
  border-bottom-color: rgba(247, 191, 71, 0.9);
}
.custom-card-header--border.custom-card-header--color-secondary-01 {
  border-bottom-color: rgba(134, 142, 150, 0.1);
}
.custom-card-header--border.custom-card-header--color-secondary-02 {
  border-bottom-color: rgba(134, 142, 150, 0.2);
}
.custom-card-header--border.custom-card-header--color-secondary-03 {
  border-bottom-color: rgba(134, 142, 150, 0.3);
}
.custom-card-header--border.custom-card-header--color-secondary-04 {
  border-bottom-color: rgba(134, 142, 150, 0.4);
}
.custom-card-header--border.custom-card-header--color-secondary-05 {
  border-bottom-color: rgba(134, 142, 150, 0.5);
}
.custom-card-header--border.custom-card-header--color-secondary-06 {
  border-bottom-color: rgba(134, 142, 150, 0.6);
}
.custom-card-header--border.custom-card-header--color-secondary-07 {
  border-bottom-color: rgba(134, 142, 150, 0.7);
}
.custom-card-header--border.custom-card-header--color-secondary-08 {
  border-bottom-color: rgba(134, 142, 150, 0.8);
}
.custom-card-header--border.custom-card-header--color-secondary-09 {
  border-bottom-color: rgba(134, 142, 150, 0.9);
}
.custom-card-header--border.custom-card-header--color-dark-01 {
  border-bottom-color: rgba(63, 70, 81, 0.1);
}
.custom-card-header--border.custom-card-header--color-dark-02 {
  border-bottom-color: rgba(63, 70, 81, 0.2);
}
.custom-card-header--border.custom-card-header--color-dark-03 {
  border-bottom-color: rgba(63, 70, 81, 0.3);
}
.custom-card-header--border.custom-card-header--color-dark-04 {
  border-bottom-color: rgba(63, 70, 81, 0.4);
}
.custom-card-header--border.custom-card-header--color-dark-05 {
  border-bottom-color: rgba(63, 70, 81, 0.5);
}
.custom-card-header--border.custom-card-header--color-dark-06 {
  border-bottom-color: rgba(63, 70, 81, 0.6);
}
.custom-card-header--border.custom-card-header--color-dark-07 {
  border-bottom-color: rgba(63, 70, 81, 0.7);
}
.custom-card-header--border.custom-card-header--color-dark-08 {
  border-bottom-color: rgba(63, 70, 81, 0.8);
}
.custom-card-header--border.custom-card-header--color-dark-09 {
  border-bottom-color: rgba(63, 70, 81, 0.9);
}
.custom-card-header--border.custom-card-header--color-light-01 {
  border-bottom-color: rgba(248, 249, 250, 0.1);
}
.custom-card-header--border.custom-card-header--color-light-02 {
  border-bottom-color: rgba(248, 249, 250, 0.2);
}
.custom-card-header--border.custom-card-header--color-light-03 {
  border-bottom-color: rgba(248, 249, 250, 0.3);
}
.custom-card-header--border.custom-card-header--color-light-04 {
  border-bottom-color: rgba(248, 249, 250, 0.4);
}
.custom-card-header--border.custom-card-header--color-light-05 {
  border-bottom-color: rgba(248, 249, 250, 0.5);
}
.custom-card-header--border.custom-card-header--color-light-06 {
  border-bottom-color: rgba(248, 249, 250, 0.6);
}
.custom-card-header--border.custom-card-header--color-light-07 {
  border-bottom-color: rgba(248, 249, 250, 0.7);
}
.custom-card-header--border.custom-card-header--color-light-08 {
  border-bottom-color: rgba(248, 249, 250, 0.8);
}
.custom-card-header--border.custom-card-header--color-light-09 {
  border-bottom-color: rgba(248, 249, 250, 0.9);
}
.custom-card-header--border.custom-card-header--color-purple-01 {
  border-bottom-color: rgba(202, 142, 255, 0.1);
}
.custom-card-header--border.custom-card-header--color-purple-02 {
  border-bottom-color: rgba(202, 142, 255, 0.2);
}
.custom-card-header--border.custom-card-header--color-purple-03 {
  border-bottom-color: rgba(202, 142, 255, 0.3);
}
.custom-card-header--border.custom-card-header--color-purple-04 {
  border-bottom-color: rgba(202, 142, 255, 0.4);
}
.custom-card-header--border.custom-card-header--color-purple-05 {
  border-bottom-color: rgba(202, 142, 255, 0.5);
}
.custom-card-header--border.custom-card-header--color-purple-06 {
  border-bottom-color: rgba(202, 142, 255, 0.6);
}
.custom-card-header--border.custom-card-header--color-purple-07 {
  border-bottom-color: rgba(202, 142, 255, 0.7);
}
.custom-card-header--border.custom-card-header--color-purple-08 {
  border-bottom-color: rgba(202, 142, 255, 0.8);
}
.custom-card-header--border.custom-card-header--color-purple-09 {
  border-bottom-color: rgba(202, 142, 255, 0.9);
}
.custom-card-header--border.custom-card-header--color-primary {
  border-bottom-color: #1EB7FF;
}
.custom-card-header--border.custom-card-header--color-secondary {
  border-bottom-color: #868E96;
}
.custom-card-header--border.custom-card-header--color-success {
  border-bottom-color: #1BB934;
}
.custom-card-header--border.custom-card-header--color-info {
  border-bottom-color: #33AE9A;
}
.custom-card-header--border.custom-card-header--color-warning {
  border-bottom-color: #F27212;
}
.custom-card-header--border.custom-card-header--color-danger {
  border-bottom-color: #ED1C24;
}
.custom-card-header--border.custom-card-header--color-light {
  border-bottom-color: #F8F9FA;
}
.custom-card-header--border.custom-card-header--color-dark {
  border-bottom-color: #3F4651;
}

/* Avatar Variants Definitions */
.hr-text {
  display: flex;
  align-items: center;
}
.hr-text:before, .hr-text:after {
  flex: 1 1 auto;
  border-top: 1px solid #DEE2E6;
}
.hr-text:after {
  content: " ";
}
.hr-text > *:first-child {
  padding-left: 0;
  padding-right: 1rem;
  text-align: left;
  color: #8493A5;
}
.hr-text.hr-text-center:before {
  content: " ";
}
.hr-text.hr-text-center > *:first-child {
  text-align: center;
  padding-left: 1rem;
}
.hr-text.hr-text-right:before {
  content: " ";
}
.hr-text.hr-text-right:after {
  content: none;
}
.hr-text.hr-text-right > *:first-child {
  text-align: right;
  padding-left: 1rem;
  padding-right: 0;
}

.icon-with-badge {
  position: relative;
  display: inline-block;
}
.icon-with-badge .icon-with-badge__badge {
  position: absolute;
  right: -8px;
  top: -4px;
  padding: 2px 5px;
  line-height: 12px;
}

/* Avatar Variants Definitions */
.layout {
  display: flex;
  min-height: 100vh;
}
.layout__navbar {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 1030;
  background: #F9FAFC;
  /* noscript fallback */
}
@media (max-width: 991.98px) {
  .layout__navbar {
    left: 0;
    background: #FFF;
    border-bottom: 1px solid #CED4DA;
  }
}
.layout__navbar + .layout__navbar {
  top: 56px;
}
.layout__navbar .navbar {
  z-index: 2;
}
.layout__navbar .navbar + .navbar {
  z-index: 1;
}
.layout__sidebar {
  flex: 0 0 auto;
  height: 100vh;
  width: 250px;
}
@media (min-width: 992px) {
  .layout__sidebar--slim {
    width: 60px;
  }
  .layout__sidebar--slim ~ .layout__wrap .layout__navbar {
    left: 60px;
  }
  .layout__sidebar--collapsed {
    width: 0;
  }
  .layout__sidebar--collapsed ~ .layout__wrap .layout__navbar {
    left: 0;
  }
}
@media (max-width: 991.98px) {
  .layout__sidebar {
    width: 0;
  }
}
.layout__content {
  padding: 1.5rem;
}
@media (max-width: 991.98px) {
  .layout__content {
    padding: 0.5rem;
  }
}
.layout__wrap {
  flex: 1 1 auto;
  width: 100%;
}
.layout--only-navbar .layout__navbar {
  background: #FFF;
  border-bottom: 1px solid #CED4DA;
}

/* Avatar Variants Definitions */
.sidebar {
  position: fixed;
  top: 0;
  border-right: 1px solid #DEE2E6;
  box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.035);
  left: 0;
  bottom: 0;
  z-index: 1031;
  display: flex;
  width: 250px;
  flex-direction: column;
  background: #FFF;
}
.sidebar__section {
  flex: 0 0 auto;
  padding: 1.5rem;
}
.sidebar__section--fluid {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.sidebar__section--fluid::-webkit-scrollbar {
  display: none;
  width: 0.25rem;
}
.sidebar__section--fluid::-webkit-scrollbar-track {
  background-color: #F8F9FA;
}
.sidebar__section--fluid::-webkit-scrollbar-thumb {
  background-color: #DEE2E6;
}
.sidebar__section--cover {
  padding: 0;
}
.sidebar__close {
  display: none;
}
.sidebar__close > a {
  color: #DEE2E6;
}
.sidebar__close > a:hover {
  color: #ADB5BD;
}
.sidebar__brand {
  color: #1EB7FF;
}
.sidebar__brand:hover {
  color: #1EB7FF;
}
.sidebar__link {
  color: #1EB7FF;
}
.sidebar__link:hover {
  color: #1EB7FF;
  text-decoration: none;
}
.sidebar__link:active, .sidebar__link:focus {
  text-decoration: none;
}
.sidebar__link--muted {
  color: #5D636D;
}
.sidebar__show-slim {
  display: none;
}
.sidebar__mobile-fluid {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
@media (max-width: 991.98px) {
  .sidebar__mobile-fluid {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
  }
  .sidebar__mobile-fluid::-webkit-scrollbar {
    display: none;
    width: 0.25rem;
  }
  .sidebar__mobile-fluid::-webkit-scrollbar-track {
    background-color: #F8F9FA;
  }
  .sidebar__mobile-fluid::-webkit-scrollbar-thumb {
    background-color: #DEE2E6;
  }
  .sidebar__mobile-fluid .sidebar__section--fluid {
    flex: 1 0 auto;
    overflow-y: initial;
    -webkit-overflow-scrolling: unset;
  }
}

@media (min-width: 992px) {
  .sidebar--slim.sidebar--collapsed {
    width: 60px;
  }
  .sidebar--slim.sidebar--collapsed .sidebar__section:not(.sidebar__section--cover) {
    padding: 1rem;
    text-align: center;
  }
  .sidebar--slim.sidebar--collapsed .sidebar__show-slim {
    display: block;
  }
  .sidebar--slim.sidebar--collapsed .sidebar__hide-slim {
    display: none;
  }
  .sidebar--slim.sidebar--collapsed .sidebar__section--fluid {
    overflow-y: visible;
  }
  .sidebar:not(.sidebar--slim).sidebar--collapsed {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    touch-action: none;
    transform: translateZ(0);
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .sidebar--collapsed {
    transform: translateZ(0) translateX(-100%);
  }
  .sidebar--animations-disabled {
    transition: none;
  }
  .sidebar__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: block;
  }
  .sidebar:not(.sidebar--collapsed) {
    box-shadow: 0px 0px 1rem 0px rgba(31, 45, 61, 0.15);
  }
}
/* Avatar Variants Definitions */
.sidebar-menu {
  list-style-type: none;
  padding: 0;
}
.sidebar-menu__entry.active:not(.open) > .sidebar-menu__entry__link {
  background-color: transparent;
  color: #1F2D3D;
}
.sidebar-menu__entry__link {
  display: flex;
  align-items: center;
  color: #8493A5;
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: calc(1.5rem + 0px);
}
.sidebar-menu__entry__link > i:first-child {
  margin-right: 0.5rem;
  flex: 0 0 auto;
}
.sidebar-menu__entry__link > span:not(.badge) {
  flex: 1 1 auto;
}
.sidebar-menu__entry__link:hover {
  text-decoration: none;
  background-color: transparent;
  color: #1F2D3D;
}
.sidebar-menu__entry--nested .sidebar-menu__entry__link::after {
  flex: 0 0 auto;
  margin-left: 0.5rem;
  width: 1.28571em;
  text-align: center;
  font-family: FontAwesome;
  content: "\f104";
}
.sidebar-menu__entry--nested .sidebar-menu__entry__link:hover::after {
  color: #1F2D3D;
}
.sidebar-menu__entry--nested.open .sidebar-menu__entry__link::after {
  content: "\f107";
}
.sidebar-menu__entry--no-caret .sidebar-menu__entry__link::after {
  content: none !important;
}

.sidebar-submenu {
  padding: 0;
  list-style-type: none;
  height: 0;
  overflow: hidden;
}
.sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link {
  background-color: transparent;
  color: #1F2D3D;
}
.sidebar-submenu__entry--nested > .sidebar-submenu__entry__link::after {
  flex: 0 0 auto;
  margin-left: 0.5rem;
  width: 1.28571em;
  text-align: center;
  font-family: FontAwesome;
  content: "\f104";
}
.sidebar-submenu__entry--nested.open > .sidebar-submenu__entry__link::after {
  content: "\f107";
}
.sidebar-submenu__entry__link {
  display: flex;
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: calc(2.5rem + 1rem);
  color: #8493A5;
}
.sidebar-submenu__entry__link > span {
  flex: 1 1 auto;
}
.sidebar-submenu__entry__link:hover {
  text-decoration: none;
  background-color: transparent;
  color: #1F2D3D;
}

.open.sidebar-menu__entry--nested > .sidebar-submenu__entry__link::after {
  content: "\f107";
}
.open > .sidebar-submenu {
  height: auto;
}

.sidebar-submenu .sidebar-submenu .sidebar-submenu__entry__link {
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: calc(2.5rem + 2rem);
  font-size: 12px;
}

/* ------ Slim Sidebar ---------- */
@media (min-width: 992px) {
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested {
    position: relative;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested .sidebar-menu__entry__link::after {
    content: none;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu {
    display: none;
    position: absolute;
    left: calc(100% - 3px);
    top: 0;
    right: auto;
    bottom: auto;
    min-width: 250px;
    background: #FFF;
    border: 1px solid #DEE2E6;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
    box-shadow: none;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu .sidebar-submenu__entry__link {
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: calc(1.5rem + 0.5rem);
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu .sidebar-submenu__entry__link:hover {
    text-decoration: none;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu > .sidebar-submenu__entry > .sidebar-submenu__entry__link {
    padding-left: 1rem;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested:hover > .sidebar-submenu {
    display: block;
    height: auto;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry__link {
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry__link > span {
    display: none;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry__link > i:first-child {
    margin-right: 0;
  }
  .sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry:hover .sidebar-menu__entry__link {
    text-decoration: none;
  }
}
/* Avatar Variants Definitions */
.navbar.navbar-shadow {
  box-shadow: 0px -4px 10px 2px rgba(206, 212, 218, 0.5);
  border-bottom: 1px solid #DEE2E6;
}

.navbar-toggler.nav-link {
  font-size: 0.875rem;
}

.navbar .navbar-nav.nav-pills .nav-link {
  padding: 0.5rem 1rem;
}

.navbar .nav-link {
  outline: none !important;
}

/**
    Adding this class to the Navbar will allow to
    have a collapsesd menu with additional UNCOLLAPSABLE elements
    after. This will push the collapse container to be the last in the navbar.
*/
.navbar-multi-collapse.navbar-expand-sm .navbar-collapse {
  align-self: stretch;
}
@media (max-width: 575.98px) {
  .navbar-multi-collapse.navbar-expand-sm .navbar-collapse-wrap {
    display: flex;
  }
  .navbar-multi-collapse.navbar-expand-sm .navbar-collapse {
    order: 999;
  }
}
.navbar-multi-collapse.navbar-expand-md .navbar-collapse {
  align-self: stretch;
}
@media (max-width: 767.98px) {
  .navbar-multi-collapse.navbar-expand-md .navbar-collapse-wrap {
    display: flex;
  }
  .navbar-multi-collapse.navbar-expand-md .navbar-collapse {
    order: 999;
  }
}
.navbar-multi-collapse.navbar-expand-lg .navbar-collapse {
  align-self: stretch;
}
@media (max-width: 991.98px) {
  .navbar-multi-collapse.navbar-expand-lg .navbar-collapse-wrap {
    display: flex;
  }
  .navbar-multi-collapse.navbar-expand-lg .navbar-collapse {
    order: 999;
  }
}
.navbar-multi-collapse.navbar-expand-xl .navbar-collapse {
  align-self: stretch;
}
@media (max-width: 1199.98px) {
  .navbar-multi-collapse.navbar-expand-xl .navbar-collapse-wrap {
    display: flex;
  }
  .navbar-multi-collapse.navbar-expand-xl .navbar-collapse {
    order: 999;
  }
}

/**
    Nav Accent  =================================
*/
.navbar-nav.nav-accent {
  align-self: stretch;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.navbar-nav.nav-accent .nav-item {
  display: flex;
  align-self: stretch;
}
.navbar-nav.nav-accent .nav-item .nav-link {
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;
  padding: 1rem;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .nav-accent {
    align-self: center;
    margin: 0;
  }
  .navbar-expand-sm .nav-accent .nav-item {
    display: block;
  }
  .navbar-expand-sm .nav-accent .nav-item .nav-link {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  .navbar-expand-sm .nav-accent .nav-item .nav-link.active, .navbar-expand-sm .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-sm .nav-accent .nav-item .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
  }
  .navbar-expand-sm .nav-accent .dropdown-menu {
    background: none;
    border: none;
  }
  .navbar-expand-sm .nav-accent .nav-item .nav-link,
  .navbar-expand-sm .nav-accent .dropdown-menu .dropdown-item {
    border-radius: 0.25rem;
  }
  .navbar-expand-sm .nav-accent .dropdown.show > .nav-link {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-light .nav-accent .dropdown-menu .dropdown-item {
    color: #8493A5;
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-light .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-sm.navbar-light .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-light .nav-accent .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar-expand-sm.navbar-dark .nav-accent .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-dark .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-sm.navbar-dark .nav-accent .dropdown-menu .dropdown-item:hover {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-dark .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-dark .nav-accent .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .nav-accent {
    align-self: center;
    margin: 0;
  }
  .navbar-expand-md .nav-accent .nav-item {
    display: block;
  }
  .navbar-expand-md .nav-accent .nav-item .nav-link {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  .navbar-expand-md .nav-accent .nav-item .nav-link.active, .navbar-expand-md .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-md .nav-accent .nav-item .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
  }
  .navbar-expand-md .nav-accent .dropdown-menu {
    background: none;
    border: none;
  }
  .navbar-expand-md .nav-accent .nav-item .nav-link,
  .navbar-expand-md .nav-accent .dropdown-menu .dropdown-item {
    border-radius: 0.25rem;
  }
  .navbar-expand-md .nav-accent .dropdown.show > .nav-link {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-light .nav-accent .dropdown-menu .dropdown-item {
    color: #8493A5;
    box-shadow: none;
  }
  .navbar-expand-md.navbar-light .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-md.navbar-light .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-light .nav-accent .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar-expand-md.navbar-dark .nav-accent .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
  }
  .navbar-expand-md.navbar-dark .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-md.navbar-dark .nav-accent .dropdown-menu .dropdown-item:hover {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-dark .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-dark .nav-accent .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .nav-accent {
    align-self: center;
    margin: 0;
  }
  .navbar-expand-lg .nav-accent .nav-item {
    display: block;
  }
  .navbar-expand-lg .nav-accent .nav-item .nav-link {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  .navbar-expand-lg .nav-accent .nav-item .nav-link.active, .navbar-expand-lg .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-lg .nav-accent .nav-item .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
  }
  .navbar-expand-lg .nav-accent .dropdown-menu {
    background: none;
    border: none;
  }
  .navbar-expand-lg .nav-accent .nav-item .nav-link,
  .navbar-expand-lg .nav-accent .dropdown-menu .dropdown-item {
    border-radius: 0.25rem;
  }
  .navbar-expand-lg .nav-accent .dropdown.show > .nav-link {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-light .nav-accent .dropdown-menu .dropdown-item {
    color: #8493A5;
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-light .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-lg.navbar-light .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-light .nav-accent .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar-expand-lg.navbar-dark .nav-accent .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-dark .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-lg.navbar-dark .nav-accent .dropdown-menu .dropdown-item:hover {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-dark .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-dark .nav-accent .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .nav-accent {
    align-self: center;
    margin: 0;
  }
  .navbar-expand-xl .nav-accent .nav-item {
    display: block;
  }
  .navbar-expand-xl .nav-accent .nav-item .nav-link {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  .navbar-expand-xl .nav-accent .nav-item .nav-link.active, .navbar-expand-xl .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-xl .nav-accent .nav-item .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
  }
  .navbar-expand-xl .nav-accent .dropdown-menu {
    background: none;
    border: none;
  }
  .navbar-expand-xl .nav-accent .nav-item .nav-link,
  .navbar-expand-xl .nav-accent .dropdown-menu .dropdown-item {
    border-radius: 0.25rem;
  }
  .navbar-expand-xl .nav-accent .dropdown.show > .nav-link {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-light .nav-accent .dropdown-menu .dropdown-item {
    color: #8493A5;
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-light .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-xl.navbar-light .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-light .nav-accent .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar-expand-xl.navbar-dark .nav-accent .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-dark .nav-accent .dropdown-menu .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar-expand-xl.navbar-dark .nav-accent .dropdown-menu .dropdown-item:hover {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-dark .nav-accent .nav-item .nav-link:hover {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-dark .nav-accent .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
}

.navbar-expand .nav-accent {
  align-self: center;
  margin: 0;
}
.navbar-expand .nav-accent .nav-item {
  display: block;
}
.navbar-expand .nav-accent .nav-item .nav-link {
  display: block;
  margin: 0;
  padding: 0.5rem 1rem;
}
.navbar-expand .nav-accent .nav-item .nav-link.active, .navbar-expand .nav-accent .nav-item .nav-link:hover {
  box-shadow: none;
}
.navbar-expand .nav-accent .nav-item .nav-link.active {
  background-color: #E9ECEF;
  color: #1F2D3D;
}
.navbar-expand .nav-accent .dropdown-menu {
  background: none;
  border: none;
}
.navbar-expand .nav-accent .nav-item .nav-link,
.navbar-expand .nav-accent .dropdown-menu .dropdown-item {
  border-radius: 0.25rem;
}
.navbar-expand .nav-accent .dropdown.show > .nav-link {
  box-shadow: none;
}
.navbar-expand.navbar-light .nav-accent .dropdown-menu .dropdown-item {
  color: #8493A5;
  box-shadow: none;
}
.navbar-expand.navbar-light .nav-accent .dropdown-menu .dropdown-item.active {
  color: #1F2D3D;
}
.navbar-expand.navbar-light .nav-accent .nav-item .nav-link:hover {
  box-shadow: none;
}
.navbar-expand.navbar-light .nav-accent .dropdown.show > .nav-link {
  color: #1F2D3D;
}
.navbar-expand.navbar-dark .nav-accent .dropdown-menu .dropdown-item {
  color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
.navbar-expand.navbar-dark .nav-accent .dropdown-menu .dropdown-item.active {
  color: #1F2D3D;
}
.navbar-expand.navbar-dark .nav-accent .dropdown-menu .dropdown-item:hover {
  box-shadow: none;
}
.navbar-expand.navbar-dark .nav-accent .nav-item .nav-link:hover {
  box-shadow: none;
}
.navbar-expand.navbar-dark .nav-accent .dropdown.show > .nav-link {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-light .nav-accent .nav-item .nav-link:hover {
  box-shadow: inset 0px -3px 0px 0px #f8fafc;
}
.navbar-light .nav-accent .dropdown.show > .nav-link {
  box-shadow: inset 0px -3px 0px 0px #f8fafc;
  color: #1F2D3D;
}

.navbar-dark .nav-accent .nav-item .nav-link:hover {
  box-shadow: inset 0px -3px 0px 0px rgba(153, 153, 153, 0.75);
}
.navbar-dark .nav-accent .dropdown.show > .nav-link {
  box-shadow: inset 0px -3px 0px 0px rgba(153, 153, 153, 0.75);
  color: #FFF;
}

.navbar-light .nav-accent .nav-item .nav-link.active,
.navbar-dark .nav-accent .nav-item .nav-link.active {
  box-shadow: inset 0px -3px 0px 0px #1EB7FF;
}

/**
    Nav Pills ===================================
*/
.navbar .nav.nav-pills .dropdown.show > .nav-link {
  color: #fff;
}

.navbar.navbar-dark .nav.nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar.navbar-dark .nav.nav-pills .nav-link.active {
  color: #fff;
}
.navbar.navbar-light .nav.nav-pills .nav-link {
  color: #8493A5;
}
.navbar.navbar-light .nav.nav-pills .nav-link.active {
  color: #fff;
}

@media (max-width: 575.98px) {
  .navbar.navbar-expand-sm .nav-pills .dropdown-item.active,
  .navbar.navbar-expand-sm .nav-pills .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .navbar.navbar-expand-sm .nav-pills .dropdown-item, .navbar.navbar-expand-sm .nav-pills .dropdown-item:hover, .navbar.navbar-expand-sm .nav-pills .dropdown-item:focus {
    background: none;
  }
  .navbar.navbar-expand-sm .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-sm .nav-pills .dropdown.show > .nav-link {
    background: none;
  }
  .navbar.navbar-expand-sm .nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar.navbar-expand-sm .nav-pills .dropdown-menu.show {
    background: none;
    border: none;
  }
  .navbar.navbar-expand-sm.navbar-light .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-sm.navbar-light .nav-pills .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-sm.navbar-light .nav-pills .dropdown-item {
    color: #8493A5;
  }
  .navbar.navbar-expand-sm.navbar-light .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-sm.navbar-dark .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-sm.navbar-dark .nav-pills .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar.navbar-expand-sm.navbar-dark .nav-pills .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar.navbar-expand-sm.navbar-dark .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
}
@media (max-width: 767.98px) {
  .navbar.navbar-expand-md .nav-pills .dropdown-item.active,
  .navbar.navbar-expand-md .nav-pills .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .navbar.navbar-expand-md .nav-pills .dropdown-item, .navbar.navbar-expand-md .nav-pills .dropdown-item:hover, .navbar.navbar-expand-md .nav-pills .dropdown-item:focus {
    background: none;
  }
  .navbar.navbar-expand-md .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-md .nav-pills .dropdown.show > .nav-link {
    background: none;
  }
  .navbar.navbar-expand-md .nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar.navbar-expand-md .nav-pills .dropdown-menu.show {
    background: none;
    border: none;
  }
  .navbar.navbar-expand-md.navbar-light .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-md.navbar-light .nav-pills .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-md.navbar-light .nav-pills .dropdown-item {
    color: #8493A5;
  }
  .navbar.navbar-expand-md.navbar-light .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-md.navbar-dark .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-md.navbar-dark .nav-pills .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar.navbar-expand-md.navbar-dark .nav-pills .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar.navbar-expand-md.navbar-dark .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
}
@media (max-width: 991.98px) {
  .navbar.navbar-expand-lg .nav-pills .dropdown-item.active,
  .navbar.navbar-expand-lg .nav-pills .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .navbar.navbar-expand-lg .nav-pills .dropdown-item, .navbar.navbar-expand-lg .nav-pills .dropdown-item:hover, .navbar.navbar-expand-lg .nav-pills .dropdown-item:focus {
    background: none;
  }
  .navbar.navbar-expand-lg .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-lg .nav-pills .dropdown.show > .nav-link {
    background: none;
  }
  .navbar.navbar-expand-lg .nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar.navbar-expand-lg .nav-pills .dropdown-menu.show {
    background: none;
    border: none;
  }
  .navbar.navbar-expand-lg.navbar-light .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-lg.navbar-light .nav-pills .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-lg.navbar-light .nav-pills .dropdown-item {
    color: #8493A5;
  }
  .navbar.navbar-expand-lg.navbar-light .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-lg.navbar-dark .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-lg.navbar-dark .nav-pills .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar.navbar-expand-lg.navbar-dark .nav-pills .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar.navbar-expand-lg.navbar-dark .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
}
@media (max-width: 1199.98px) {
  .navbar.navbar-expand-xl .nav-pills .dropdown-item.active,
  .navbar.navbar-expand-xl .nav-pills .nav-link.active {
    background-color: #E9ECEF;
    color: #1F2D3D;
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .navbar.navbar-expand-xl .nav-pills .dropdown-item, .navbar.navbar-expand-xl .nav-pills .dropdown-item:hover, .navbar.navbar-expand-xl .nav-pills .dropdown-item:focus {
    background: none;
  }
  .navbar.navbar-expand-xl .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-xl .nav-pills .dropdown.show > .nav-link {
    background: none;
  }
  .navbar.navbar-expand-xl .nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar.navbar-expand-xl .nav-pills .dropdown-menu.show {
    background: none;
    border: none;
  }
  .navbar.navbar-expand-xl.navbar-light .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-xl.navbar-light .nav-pills .dropdown.show > .nav-link {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-xl.navbar-light .nav-pills .dropdown-item {
    color: #8493A5;
  }
  .navbar.navbar-expand-xl.navbar-light .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
  .navbar.navbar-expand-xl.navbar-dark .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar.navbar-expand-xl.navbar-dark .nav-pills .dropdown.show > .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar.navbar-expand-xl.navbar-dark .nav-pills .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar.navbar-expand-xl.navbar-dark .nav-pills .dropdown-item.active {
    color: #1F2D3D;
  }
}
.navbar.navbar-expand .nav-pills .dropdown-item.active,
.navbar.navbar-expand .nav-pills .nav-link.active {
  background-color: #E9ECEF;
  color: #1F2D3D;
  border-radius: 0.25rem;
  box-shadow: none;
}
.navbar.navbar-expand .nav-pills .dropdown-item, .navbar.navbar-expand .nav-pills .dropdown-item:hover, .navbar.navbar-expand .nav-pills .dropdown-item:focus {
  background: none;
}
.navbar.navbar-expand .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
.navbar.navbar-expand .nav-pills .dropdown.show > .nav-link {
  background: none;
}
.navbar.navbar-expand .nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
.navbar.navbar-expand .nav-pills .dropdown-menu.show {
  background: none;
  border: none;
}
.navbar.navbar-expand.navbar-light .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
.navbar.navbar-expand.navbar-light .nav-pills .dropdown.show > .nav-link {
  color: #1F2D3D;
}
.navbar.navbar-expand.navbar-light .nav-pills .dropdown-item {
  color: #8493A5;
}
.navbar.navbar-expand.navbar-light .nav-pills .dropdown-item.active {
  color: #1F2D3D;
}
.navbar.navbar-expand.navbar-dark .nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
.navbar.navbar-expand.navbar-dark .nav-pills .dropdown.show > .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.navbar.navbar-expand.navbar-dark .nav-pills .dropdown-item {
  color: rgba(255, 255, 255, 0.5);
}
.navbar.navbar-expand.navbar-dark .nav-pills .dropdown-item.active {
  color: #1F2D3D;
}

/* Avatar Variants Definitions */
/**
 *  background, border, border-slim-submenu, theme-color,
 *  brand-color, text-color, text-color-muted,
 *  link-color, link-color-hover, link-color-muted,
 *  scroll-rail-color, scroll-thumb-color,
 *  navbar-background, navbar-brand,
 *  navbar-menu-item-bg, navbar-menu-item-color,
 *  menu-background-color, menu-text-color, menu-icon-color, menu-arrow-color
 *  (OPTIONAL) menu-slim-backgroud-color, menu-slim-text-color, menu-slim-icon-color, menu-slim-arrow-color
 *  menu-background-color-hover, menu-text-color-hover, menu-icon-color-hover, menu-arrow-color-hover
 *  (OPTIONAL) menu-slim-background-color-hover, menu-slim-text-color-hover, menu-slim-icon-color-hover, menu-slim-arrow-color-hover
 *  menu-background-color-active, menu-text-color-active, menu-icon-color-active, menu-arrow-color-active
 *  (OPTIONAL) menu-slim-background-color-active, menu-slim-text-color-active, menu-slim-icon-color-active, menu-slim-arrow-color-active
 */
/**
 * All of the variables from apply-sidebar-theme
 * and additional menu-accent-color,
 * (OPTIONAL) menu-accent-color-hover menu-accent-color-active
 */
/**
        Theme Colors
**/
/**
        DARK Theme
**/
.layout--theme--dark--primary {
  --theme-theme-color: #1EB7FF;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #1EB7FF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1EB7FF;
  --theme-menu-accent-color-hover: #1EB7FF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #1EB7FF;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #1EB7FF;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}

.theme-dark-primary {
  --theme-theme-color: #1EB7FF;
}

.navbar-dark-primary {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #1EB7FF;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #1EB7FF;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #1EB7FF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1EB7FF;
  --theme-menu-accent-color-hover: #1EB7FF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--success {
  --theme-theme-color: #1BB934;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #1BB934;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1BB934;
  --theme-menu-accent-color-hover: #1BB934;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #1BB934;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #1BB934;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}

.theme-dark-success {
  --theme-theme-color: #1BB934;
}

.navbar-dark-success {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #1BB934;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #1BB934;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #1BB934;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1BB934;
  --theme-menu-accent-color-hover: #1BB934;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--info {
  --theme-theme-color: #33AE9A;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #33AE9A;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #33AE9A;
  --theme-menu-accent-color-hover: #33AE9A;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #33AE9A;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #33AE9A;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}

.theme-dark-info {
  --theme-theme-color: #33AE9A;
}

.navbar-dark-info {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #33AE9A;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #33AE9A;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #33AE9A;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #33AE9A;
  --theme-menu-accent-color-hover: #33AE9A;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--danger {
  --theme-theme-color: #ED1C24;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #ED1C24;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #ED1C24;
  --theme-menu-accent-color-hover: #ED1C24;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #ED1C24;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #ED1C24;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}

.theme-dark-danger {
  --theme-theme-color: #ED1C24;
}

.navbar-dark-danger {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #ED1C24;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #ED1C24;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #ED1C24;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #ED1C24;
  --theme-menu-accent-color-hover: #ED1C24;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--warning {
  --theme-theme-color: #F27212;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #F27212;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F27212;
  --theme-menu-accent-color-hover: #F27212;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #F27212;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #F27212;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}

.theme-dark-warning {
  --theme-theme-color: #F27212;
}

.navbar-dark-warning {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #F27212;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #F27212;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #F27212;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F27212;
  --theme-menu-accent-color-hover: #F27212;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--indigo {
  --theme-theme-color: #6610f2;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #6610f2;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #6610f2;
  --theme-menu-accent-color-hover: #6610f2;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #6610f2;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #6610f2;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}

.theme-dark-indigo {
  --theme-theme-color: #6610f2;
}

.navbar-dark-indigo {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #6610f2;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #6610f2;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #6610f2;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #6610f2;
  --theme-menu-accent-color-hover: #6610f2;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--purple {
  --theme-theme-color: #CA8EFF;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #CA8EFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #CA8EFF;
  --theme-menu-accent-color-hover: #CA8EFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #CA8EFF;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #CA8EFF;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}

.theme-dark-purple {
  --theme-theme-color: #CA8EFF;
}

.navbar-dark-purple {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #CA8EFF;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #CA8EFF;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #CA8EFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #CA8EFF;
  --theme-menu-accent-color-hover: #CA8EFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--pink {
  --theme-theme-color: #e83e8c;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #e83e8c;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #e83e8c;
  --theme-menu-accent-color-hover: #e83e8c;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #e83e8c;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #e83e8c;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}

.theme-dark-pink {
  --theme-theme-color: #e83e8c;
}

.navbar-dark-pink {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #e83e8c;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #e83e8c;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #e83e8c;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #e83e8c;
  --theme-menu-accent-color-hover: #e83e8c;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

.layout--theme--dark--yellow {
  --theme-theme-color: #F7BF47;
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #F7BF47;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F7BF47;
  --theme-menu-accent-color-hover: #F7BF47;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #F7BF47;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #F7BF47;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}

.theme-dark-yellow {
  --theme-theme-color: #F7BF47;
}

.navbar-dark-yellow {
  --theme-navbar-background: #3F4651;
  --theme-navbar-brand-color: #F7BF47;
  --theme-navbar-between-border-color: #3F4651;
  --theme-navbar-menu-item-color: #868E96;
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-item-accent-color: #F7BF47;
  --theme-navbar-menu-item-accent-hover-color: #868E96;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}

.sidebar-dark-key {
  --theme-sidebar-background: #3F4651;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.2);
  --theme-sidebar-brand-color: #F7BF47;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #FFF;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: #868E96;
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: white;
  --theme-sidebar-link-color-muted: #868E96;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F7BF47;
  --theme-menu-accent-color-hover: #F7BF47;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #868E96;
  --theme-menu-icon-color: #868E96;
  --theme-menu-slim-icon-color: #868E96;
  --theme-menu-arrow-color: #868E96;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}

/**
        LIGHT Theme
**/
.layout--theme--light--primary {
  --theme-theme-color: #1EB7FF;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #1EB7FF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1EB7FF;
  --theme-menu-accent-color-hover: #1EB7FF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #1EB7FF;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #1EB7FF;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}

.theme-light-primary {
  --theme-theme-color: #1EB7FF;
}

.navbar-light-primary {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #1EB7FF;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #1EB7FF;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #1EB7FF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1EB7FF;
  --theme-menu-accent-color-hover: #1EB7FF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--success {
  --theme-theme-color: #1BB934;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #1BB934;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1BB934;
  --theme-menu-accent-color-hover: #1BB934;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #1BB934;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #1BB934;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}

.theme-light-success {
  --theme-theme-color: #1BB934;
}

.navbar-light-success {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #1BB934;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #1BB934;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #1BB934;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #1BB934;
  --theme-menu-accent-color-hover: #1BB934;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--info {
  --theme-theme-color: #33AE9A;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #33AE9A;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #33AE9A;
  --theme-menu-accent-color-hover: #33AE9A;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #33AE9A;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #33AE9A;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}

.theme-light-info {
  --theme-theme-color: #33AE9A;
}

.navbar-light-info {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #33AE9A;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #33AE9A;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #33AE9A;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #33AE9A;
  --theme-menu-accent-color-hover: #33AE9A;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--danger {
  --theme-theme-color: #ED1C24;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #ED1C24;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #ED1C24;
  --theme-menu-accent-color-hover: #ED1C24;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #ED1C24;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #ED1C24;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}

.theme-light-danger {
  --theme-theme-color: #ED1C24;
}

.navbar-light-danger {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #ED1C24;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #ED1C24;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #ED1C24;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #ED1C24;
  --theme-menu-accent-color-hover: #ED1C24;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--warning {
  --theme-theme-color: #F27212;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #F27212;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F27212;
  --theme-menu-accent-color-hover: #F27212;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #F27212;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #F27212;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}

.theme-light-warning {
  --theme-theme-color: #F27212;
}

.navbar-light-warning {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #F27212;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #F27212;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #F27212;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F27212;
  --theme-menu-accent-color-hover: #F27212;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--indigo {
  --theme-theme-color: #6610f2;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #6610f2;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #6610f2;
  --theme-menu-accent-color-hover: #6610f2;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #6610f2;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #6610f2;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}

.theme-light-indigo {
  --theme-theme-color: #6610f2;
}

.navbar-light-indigo {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #6610f2;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #6610f2;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #6610f2;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #6610f2;
  --theme-menu-accent-color-hover: #6610f2;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--purple {
  --theme-theme-color: #CA8EFF;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #CA8EFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #CA8EFF;
  --theme-menu-accent-color-hover: #CA8EFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #CA8EFF;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #CA8EFF;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}

.theme-light-purple {
  --theme-theme-color: #CA8EFF;
}

.navbar-light-purple {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #CA8EFF;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #CA8EFF;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #CA8EFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #CA8EFF;
  --theme-menu-accent-color-hover: #CA8EFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--pink {
  --theme-theme-color: #e83e8c;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #e83e8c;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #e83e8c;
  --theme-menu-accent-color-hover: #e83e8c;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #e83e8c;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #e83e8c;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}

.theme-light-pink {
  --theme-theme-color: #e83e8c;
}

.navbar-light-pink {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #e83e8c;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #e83e8c;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #e83e8c;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #e83e8c;
  --theme-menu-accent-color-hover: #e83e8c;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

.layout--theme--light--yellow {
  --theme-theme-color: #F7BF47;
  --theme-sidebar-background: #FFF;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #F7BF47;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F7BF47;
  --theme-menu-accent-color-hover: #F7BF47;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #F7BF47;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #F7BF47;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}

.theme-light-yellow {
  --theme-theme-color: #F7BF47;
}

.navbar-light-yellow {
  --theme-navbar-background: #FFF;
  --theme-navbar-brand-color: #F7BF47;
  --theme-navbar-between-border-color: #E9ECEF;
  --theme-navbar-menu-item-color: #8493A5;
  --theme-navbar-menu-item-hover-color: #1F2D3D;
  --theme-navbar-menu-item-active-background: rgba(0, 0, 0, 0.05);
  --theme-navbar-menu-item-active-color: #1F2D3D;
  --theme-navbar-menu-item-accent-color: #F7BF47;
  --theme-navbar-menu-item-accent-hover-color: #E9ECEF;
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}

.sidebar-light-key {
  --theme-sidebar-background: white;
  --theme-sidebar-border: 1px solid #DEE2E6;
  --theme-sidebar-border-slim-submenu: 1px solid #DEE2E6;
  --theme-sidebar-brand-color: #F7BF47;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: #8493A5;
  --theme-sidebar-text-color: #1F2D3D;
  --theme-sidebar-text-color-muted: #8493A5;
  --theme-sidebar-link-color: #1F2D3D;
  --theme-sidebar-link-color-hover: #30465f;
  --theme-sidebar-link-color-muted: #8493A5;
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #F7BF47;
  --theme-menu-accent-color-hover: #F7BF47;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: #8493A5;
  --theme-menu-icon-color: #CED4DA;
  --theme-menu-slim-icon-color: #8493A5;
  --theme-menu-arrow-color: #CED4DA;
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #1F2D3D;
  --theme-menu-slim-text-color-hover: #1F2D3D;
  --theme-menu-icon-color-hover: #1F2D3D;
  --theme-menu-slim-icon-color-hover: #1F2D3D;
  --theme-menu-arrow-color-hover: #1F2D3D;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #1F2D3D;
  --theme-menu-slim-text-color-active: #1F2D3D;
  --theme-menu-icon-color-active: #1F2D3D;
  --theme-menu-slim-icon-color-active: #1F2D3D;
  --theme-menu-arrow-color-active: #1F2D3D;
}

/**
        COLOR Theme
**/
.layout--theme--color--primary {
  --theme-theme-color: #1EB7FF;
  --theme-sidebar-background: #1EB7FF;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #1EB7FF;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #1EB7FF;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #66ceff;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}
.layout--theme--color--primary .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #66ceff !important;
}
.layout--theme--color--primary .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-primary {
  --theme-theme-color: #1EB7FF;
}

.navbar-color-primary {
  --theme-theme-color: #1EB7FF;
  --theme-navbar-background: #1EB7FF;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #1EB7FF;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #66ceff;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1EB7FF;
}
.navbar-color-primary.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-primary .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #66ceff !important;
}

.sidebar-color-key {
  --theme-theme-color: #1EB7FF;
  --theme-sidebar-background: #1EB7FF;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--success {
  --theme-theme-color: #1BB934;
  --theme-sidebar-background: #1BB934;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #1BB934;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #1BB934;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #79ec8c;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}
.layout--theme--color--success .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #79ec8c !important;
}
.layout--theme--color--success .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-success {
  --theme-theme-color: #1BB934;
}

.navbar-color-success {
  --theme-theme-color: #1BB934;
  --theme-navbar-background: #1BB934;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #1BB934;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #79ec8c;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #1BB934;
}
.navbar-color-success.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-success .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #79ec8c !important;
}

.sidebar-color-key {
  --theme-theme-color: #1BB934;
  --theme-sidebar-background: #1BB934;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--info {
  --theme-theme-color: #33AE9A;
  --theme-sidebar-background: #33AE9A;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #33AE9A;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #33AE9A;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #89dccf;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}
.layout--theme--color--info .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #89dccf !important;
}
.layout--theme--color--info .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-info {
  --theme-theme-color: #33AE9A;
}

.navbar-color-info {
  --theme-theme-color: #33AE9A;
  --theme-navbar-background: #33AE9A;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #33AE9A;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #89dccf;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #33AE9A;
}
.navbar-color-info.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-info .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #89dccf !important;
}

.sidebar-color-key {
  --theme-theme-color: #33AE9A;
  --theme-sidebar-background: #33AE9A;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--danger {
  --theme-theme-color: #ED1C24;
  --theme-sidebar-background: #ED1C24;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #ED1C24;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #ED1C24;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f47176;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}
.layout--theme--color--danger .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f47176 !important;
}
.layout--theme--color--danger .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-danger {
  --theme-theme-color: #ED1C24;
}

.navbar-color-danger {
  --theme-theme-color: #ED1C24;
  --theme-navbar-background: #ED1C24;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #ED1C24;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f47176;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #ED1C24;
}
.navbar-color-danger.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-danger .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f47176 !important;
}

.sidebar-color-key {
  --theme-theme-color: #ED1C24;
  --theme-sidebar-background: #ED1C24;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--warning {
  --theme-theme-color: #F27212;
  --theme-sidebar-background: #F27212;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #F27212;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #F27212;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f7a96e;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}
.layout--theme--color--warning .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f7a96e !important;
}
.layout--theme--color--warning .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-warning {
  --theme-theme-color: #F27212;
}

.navbar-color-warning {
  --theme-theme-color: #F27212;
  --theme-navbar-background: #F27212;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #F27212;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f7a96e;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F27212;
}
.navbar-color-warning.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-warning .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f7a96e !important;
}

.sidebar-color-key {
  --theme-theme-color: #F27212;
  --theme-sidebar-background: #F27212;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--indigo {
  --theme-theme-color: #6610f2;
  --theme-sidebar-background: #6610f2;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #6610f2;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #6610f2;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #a26ef7;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}
.layout--theme--color--indigo .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #a26ef7 !important;
}
.layout--theme--color--indigo .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-indigo {
  --theme-theme-color: #6610f2;
}

.navbar-color-indigo {
  --theme-theme-color: #6610f2;
  --theme-navbar-background: #6610f2;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #6610f2;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #a26ef7;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #6610f2;
}
.navbar-color-indigo.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-indigo .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #a26ef7 !important;
}

.sidebar-color-key {
  --theme-theme-color: #6610f2;
  --theme-sidebar-background: #6610f2;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--purple {
  --theme-theme-color: #CA8EFF;
  --theme-sidebar-background: #CA8EFF;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #CA8EFF;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #CA8EFF;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #b766ff;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}
.layout--theme--color--purple .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #b766ff !important;
}
.layout--theme--color--purple .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-purple {
  --theme-theme-color: #CA8EFF;
}

.navbar-color-purple {
  --theme-theme-color: #CA8EFF;
  --theme-navbar-background: #CA8EFF;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #CA8EFF;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #b766ff;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #CA8EFF;
}
.navbar-color-purple.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-purple .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #b766ff !important;
}

.sidebar-color-key {
  --theme-theme-color: #CA8EFF;
  --theme-sidebar-background: #CA8EFF;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--pink {
  --theme-theme-color: #e83e8c;
  --theme-sidebar-background: #e83e8c;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #e83e8c;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #e83e8c;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #ef76ae;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}
.layout--theme--color--pink .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #ef76ae !important;
}
.layout--theme--color--pink .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-pink {
  --theme-theme-color: #e83e8c;
}

.navbar-color-pink {
  --theme-theme-color: #e83e8c;
  --theme-navbar-background: #e83e8c;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #e83e8c;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #ef76ae;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #e83e8c;
}
.navbar-color-pink.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-pink .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #ef76ae !important;
}

.sidebar-color-key {
  --theme-theme-color: #e83e8c;
  --theme-sidebar-background: #e83e8c;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

.layout--theme--color--yellow {
  --theme-theme-color: #F7BF47;
  --theme-sidebar-background: #F7BF47;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
  --theme-navbar-background: #F7BF47;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #F7BF47;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f9cc6c;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}
.layout--theme--color--yellow .layout__navbar .navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f9cc6c !important;
}
.layout--theme--color--yellow .layout__sidebar .sidebar .badge {
  background-color: rgba(255, 255, 255, 0.5);
}

.theme-color-yellow {
  --theme-theme-color: #F7BF47;
}

.navbar-color-yellow {
  --theme-theme-color: #F7BF47;
  --theme-navbar-background: #F7BF47;
  --theme-navbar-brand-color: #FFF;
  --theme-navbar-between-border-color: #F7BF47;
  --theme-navbar-menu-item-color: rgba(255, 255, 255, 0.8);
  --theme-navbar-menu-item-hover-color: #FFF;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #FFF;
  --theme-navbar-menu-icon-w-badge-color: #f9cc6c;
  --theme-navbar-menu-item-accent-color: #FFF;
  --theme-navbar-menu-item-accent-hover-color: rgba(255, 255, 255, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #F7BF47;
}
.navbar-color-yellow.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-yellow .navbar.navbar-themed .icon-with-badge .icon-with-badge__badge {
  background-color: #f9cc6c !important;
}

.sidebar-color-key {
  --theme-theme-color: #F7BF47;
  --theme-sidebar-background: #F7BF47;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid rgba(255, 255, 255, 0.5);
  --theme-sidebar-brand-color: #FFF;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #FFF;
  --theme-sidebar-text-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-link-color: #FFF;
  --theme-sidebar-link-color-hover: rgba(255, 255, 255, 0.9);
  --theme-sidebar-link-color-muted: rgba(255, 255, 255, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #FFF;
  --theme-menu-accent-color-hover: #FFF;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: rgba(255, 255, 255, 0.7);
  --theme-menu-icon-color: rgba(255, 255, 255, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: rgba(255, 255, 255, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #FFF;
  --theme-menu-slim-text-color-hover: #FFF;
  --theme-menu-icon-color-hover: #FFF;
  --theme-menu-slim-icon-color-hover: #FFF;
  --theme-menu-arrow-color-hover: #FFF;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #FFF;
  --theme-menu-slim-text-color-active: #FFF;
  --theme-menu-icon-color-active: #FFF;
  --theme-menu-slim-icon-color-active: #FFF;
  --theme-menu-arrow-color-active: #FFF;
}
.sidebar-color-key .badge {
  background-color: rgba(255, 255, 255, 0.4);
}

/* Theme Helpers */
.text-theme {
  color: var(--theme-theme-color) !important;
}

.bg-theme {
  background-color: var(--theme-theme-color) !important;
}

.badge-theme {
  background-color: var(--theme-theme-color);
  color: #fff;
}

.navbar-brand {
  color: var(--theme-theme-color);
}
.navbar-brand > a {
  color: var(--theme-theme-color);
}

/* Navbar Styling */
.navbar-themed {
  background-color: var(--theme-navbar-background);
  /*
      General Navbar Elements =============
  */
  /*
      NavAccent Menu Theming
  */
  /*
      NavPills Menu Theming ===============
  */
}
.navbar-themed .nav-item > .avatar-image .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-item > .avatar-font .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-link > .avatar-image .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-link > .avatar-font .avatar__icon--stack > .fa-circle:first-child {
  color: var(--theme-navbar-background) !important;
}
.navbar-themed + nav {
  border-top: 1px solid var(--theme-navbar-between-border-color, var(--theme-navbar-background));
}
.navbar-themed .navbar-brand {
  color: var(--theme-navbar-brand-color);
}
.navbar-themed .navbar-toggler {
  color: var(--theme-navbar-menu-item-color);
}
.navbar-themed .nav .nav-link,
.navbar-themed .navbar-nav .nav-link {
  color: var(--theme-navbar-menu-item-color);
}
.navbar-themed .nav .nav-link:hover,
.navbar-themed .navbar-nav .nav-link:hover {
  color: var(--theme-navbar-menu-item-hover-color);
}
.navbar-themed .nav .nav-link.active,
.navbar-themed .navbar-nav .nav-link.active {
  color: var(--theme-navbar-menu-item-active-color);
  background: none;
  font-weight: 500;
}
.navbar-themed .nav .nav-link.active:hover,
.navbar-themed .navbar-nav .nav-link.active:hover {
  color: var(--theme-navbar-menu-item-active-color);
}
.navbar-themed .nav-accent .nav-item .nav-link:hover {
  box-shadow: inset 0px -3px 0px 0px var(--theme-navbar-menu-item-accent-hover-color);
}
.navbar-themed .nav-accent .nav-item .nav-link.active {
  box-shadow: inset 0px -3px 0px 0px var(--theme-navbar-menu-item-accent-color);
}
.navbar-themed .nav-accent .dropdown.show > .nav-link {
  box-shadow: inset 0px -3px 0px 0px var(--theme-navbar-menu-item-accent-hover-color);
  color: var(--theme-navbar-menu-item-active-color);
}
.navbar-themed .nav-accent .dropdown-menu .dropdown-item:active, .navbar-themed .nav-accent .dropdown-menu .dropdown-item.active {
  background-color: var(--theme-navbar-dropdown-active-background);
  color: var(--theme-navbar-dropdown-active-color);
}
@media (max-width: 575.98px) {
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-item.active,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-accent .nav-link, .navbar-themed.navbar-expand-sm .nav.nav-accent .nav-link:hover, .navbar-themed.navbar-expand-sm .nav.nav-accent .nav-link:focus,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-item:hover,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-accent .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-accent .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-item.active,
  .navbar-themed.navbar-expand-md .nav.nav-accent .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-accent .nav-link, .navbar-themed.navbar-expand-md .nav.nav-accent .nav-link:hover, .navbar-themed.navbar-expand-md .nav.nav-accent .nav-link:focus,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-item:hover,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-accent .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-accent .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-item.active,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-accent .nav-link, .navbar-themed.navbar-expand-lg .nav.nav-accent .nav-link:hover, .navbar-themed.navbar-expand-lg .nav.nav-accent .nav-link:focus,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-item:hover,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-accent .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-accent .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-item.active,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-accent .nav-link, .navbar-themed.navbar-expand-xl .nav.nav-accent .nav-link:hover, .navbar-themed.navbar-expand-xl .nav.nav-accent .nav-link:focus,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-item:hover,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-accent .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-accent .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-item.active,
.navbar-themed.navbar-expand .nav.nav-accent .nav-link.active {
  background-color: var(--theme-navbar-menu-item-active-background);
  color: var(--theme-navbar-menu-item-active-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-accent .nav-link, .navbar-themed.navbar-expand .nav.nav-accent .nav-link:hover, .navbar-themed.navbar-expand .nav.nav-accent .nav-link:focus,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-item:hover,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-item:focus {
  color: var(--theme-navbar-menu-item-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-accent .nested-dropdown__submenu-item--open > .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown.show > .nav-link {
  color: var(--theme-navbar-menu-item-hover-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-menu.show {
  background: none;
}
.navbar-themed.navbar-expand .nav.nav-accent .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-accent .dropdown-menu.show > .dropdown-item {
  box-shadow: none;
}
.navbar-themed .nav.nav-pills .dropdown.show > .nav-link {
  background-color: var(--theme-navbar-menu-item-active-background);
  color: var(--theme-navbar-menu-item-active-color);
}
.navbar-themed .nav.nav-pills .dropdown-menu .dropdown-item:active, .navbar-themed .nav.nav-pills .dropdown-menu .dropdown-item.active {
  background-color: var(--theme-navbar-dropdown-active-background);
  color: var(--theme-navbar-dropdown-active-color);
  font-weight: 500;
}
@media (max-width: 575.98px) {
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-item.active,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-pills .nav-link, .navbar-themed.navbar-expand-sm .nav.nav-pills .nav-link:hover, .navbar-themed.navbar-expand-sm .nav.nav-pills .nav-link:focus,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-item:hover,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-sm .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-sm .nav.nav-pills .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-item.active,
  .navbar-themed.navbar-expand-md .nav.nav-pills .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-pills .nav-link, .navbar-themed.navbar-expand-md .nav.nav-pills .nav-link:hover, .navbar-themed.navbar-expand-md .nav.nav-pills .nav-link:focus,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-item:hover,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-md .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-md .nav.nav-pills .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-item.active,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-pills .nav-link, .navbar-themed.navbar-expand-lg .nav.nav-pills .nav-link:hover, .navbar-themed.navbar-expand-lg .nav.nav-pills .nav-link:focus,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-item:hover,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-lg .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-lg .nav.nav-pills .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-item.active,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .nav-link.active {
    background-color: var(--theme-navbar-menu-item-active-background);
    color: var(--theme-navbar-menu-item-active-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-pills .nav-link, .navbar-themed.navbar-expand-xl .nav.nav-pills .nav-link:hover, .navbar-themed.navbar-expand-xl .nav.nav-pills .nav-link:focus,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-item:hover,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-item:focus {
    color: var(--theme-navbar-menu-item-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown.show > .nav-link {
    color: var(--theme-navbar-menu-item-hover-color);
    box-shadow: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-menu.show {
    background: none;
  }
  .navbar-themed.navbar-expand-xl .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
  .navbar-themed.navbar-expand-xl .nav.nav-pills .dropdown-menu.show > .dropdown-item {
    box-shadow: none;
  }
}
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-item.active,
.navbar-themed.navbar-expand .nav.nav-pills .nav-link.active {
  background-color: var(--theme-navbar-menu-item-active-background);
  color: var(--theme-navbar-menu-item-active-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-pills .nav-link, .navbar-themed.navbar-expand .nav.nav-pills .nav-link:hover, .navbar-themed.navbar-expand .nav.nav-pills .nav-link:focus,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-item:hover,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-item:focus {
  color: var(--theme-navbar-menu-item-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-pills .nested-dropdown__submenu-item--open > .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown.show > .nav-link {
  color: var(--theme-navbar-menu-item-hover-color);
  box-shadow: none;
}
.navbar-themed.navbar-expand .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-menu.show {
  background: none;
}
.navbar-themed.navbar-expand .nav.nav-pills .nested-dropdown__submenu-item--open .dropdown-menu > .dropdown-item,
.navbar-themed.navbar-expand .nav.nav-pills .dropdown-menu.show > .dropdown-item {
  box-shadow: none;
}
.navbar-themed .nav-pills .show > .nav-link {
  background-color: var(--theme-navbar-menu-item-active-background);
  color: var(--theme-navbar-menu-item-active-color);
}
.navbar-themed .nav-item > .avatar-image .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-item > .avatar-font .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-link > .avatar-image .avatar__icon--stack > .fa-circle:first-child,
.navbar-themed .nav-link > .avatar-font .avatar__icon--stack > .fa-circle:first-child {
  color: var(--theme-navbar-background) !important;
}
.navbar-themed + nav {
  border-top: 1px solid var(--theme-navbar-between-border-color, var(--theme-navbar-background));
}

/* Sidebar Styling */
.sidebar {
  background: var(--theme-sidebar-background);
  color: var(--theme-sidebar-text-color);
  border-right: var(--theme-sidebar-border);
}

.sidebar .avatar__icon--stack > .fa-circle:first-child {
  color: var(--theme-sidebar-background) !important;
}

.sidebar .text-muted {
  color: var(--theme-sidebar-text-color-muted) !important;
}

.sidebar__brand {
  color: var(--theme-sidebar-brand-color);
}

.sidebar__close > a {
  color: var(--theme-sidebar-close-color);
}

.sidebar__link {
  color: var(--theme-sidebar-link-color);
}
.sidebar__link:hover {
  color: var(--theme-sidebar-link-color-hover);
}
.sidebar__link.text-muted,
.sidebar__link .text-muted, .sidebar__link--muted {
  color: var(--theme-sidebar-link-color-muted) !important;
}

.sidebar__section--fluid::-webkit-scrollbar-track {
  background-color: var(--theme-sidebar-scroll-rail-color);
}
.sidebar__section--fluid::-webkit-scrollbar-thumb {
  background-color: var(--theme-sidebar-scroll-thumb-color);
}

.sidebar-menu__entry__icon {
  color: var(--theme-menu-icon-color);
}
.sidebar-menu__entry__link {
  color: var(--theme-menu-text-color);
  background-color: var(--theme-menu-background-color);
}
.sidebar-menu__entry__link::after {
  color: var(--theme-menu-arrow-color);
}
.sidebar-menu__entry__link:hover {
  color: var(--theme-menu-text-color-hover);
  background-color: var(--theme-menu-background-color-hover);
}
.sidebar-menu__entry__link:hover .sidebar-menu__entry__icon {
  color: var(--theme-menu-icon-color-hover);
}
.sidebar-menu__entry__link:hover::after {
  color: var(--theme-menu-arrow-color-hover) !important;
}
.sidebar-menu__entry.active:not(.open) > .sidebar-menu__entry__link {
  color: var(--theme-menu-text-color-active);
  background-color: var(--theme-menu-background-color-active);
  font-weight: 500;
}
.sidebar-menu__entry.active:not(.open) > .sidebar-menu__entry__link .sidebar-menu__entry__icon {
  color: var(--theme-menu-icon-color-active);
}
.sidebar-menu__entry.active:not(.open) > .sidebar-menu__entry__link::after {
  color: var(--theme-menu-arrow-color-active) !important;
}

.sidebar-submenu__entry__link {
  color: var(--theme-menu-text-color);
  background-color: var(--theme-menu-background-color);
}
.sidebar-submenu__entry__link::after {
  color: var(--theme-menu-arrow-color);
}
.sidebar-submenu__entry__link:hover {
  color: var(--theme-menu-text-color-hover);
  background-color: var(--theme-menu-background-color-hover);
}
.sidebar-submenu__entry__link:hover::after {
  color: var(--theme-menu-arrow-color-hover);
}
.sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link {
  color: var(--theme-menu-text-color-active);
  background-color: var(--theme-menu-background-color-active);
  font-weight: 500;
}
.sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link .sidebar-submenu__entry__icon {
  color: var(--theme-menu-icon-color-active);
}
.sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link::after {
  color: var(--theme-menu-arrow-color-active) !important;
}

@media (min-width: 992px) {
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry > .sidebar-menu__entry__link > .sidebar-menu__entry__icon {
    color: var(--theme-menu-slim-icon-color);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry:hover > .sidebar-menu__entry__link {
    background-color: var(--theme-menu-slim-background-color-hover);
    color: var(--theme-menu-slim-text-color-hover);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry:hover > .sidebar-menu__entry__link > .sidebar-menu__entry__icon {
    color: var(--theme-menu-slim-icon-color-hover);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry.active.open > .sidebar-menu__entry__link {
    background-color: var(--theme-menu-slim-background-color-active);
    color: var(--theme-menu-slim-text-color-active);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry.active.open > .sidebar-menu__entry__link > .sidebar-menu__entry__icon {
    color: var(--theme-menu-slim-icon-color-active);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu {
    background: var(--theme-sidebar-background);
    color: var(--theme-sidebar-text-color);
    border: var(--theme-sidebar-border-slim-submenu);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu .sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link {
    background-color: var(--theme-menu-slim-background-color-active);
    color: var(--theme-menu-slim-color-active);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu .sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link::after {
    color: var(--theme-menu-slim-color-active);
  }
  .sidebar.sidebar--collapsed .sidebar-menu--slim .sidebar-menu__entry--nested > .sidebar-submenu .sidebar-submenu__entry__link:hover {
    background-color: var(--theme-menu-slim-background-color-hover);
    color: var(--theme-menu-slim-text-color-hover);
  }
}
.sidebar-submenu__entry__link {
  position: relative;
  transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-submenu__entry__link::before {
  content: " ";
  position: absolute;
  display: block;
  transition: transform 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: center center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  box-shadow: inset 3px 0 0 0 var(--theme-menu-accent-color-hover);
  transform: translateZ(0) scaleY(0);
  pointer-events: none;
}
.sidebar-submenu__entry__link::after {
  transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-submenu__entry.active:not(.open) > .sidebar-submenu__entry__link::before {
  transform: translateZ(0) scaleY(1);
}

.sidebar-menu__entry__link {
  position: relative;
  transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-menu__entry__link::before {
  content: " ";
  position: absolute;
  display: block;
  transition: transform 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: center center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  box-shadow: inset 3px 0 0 0 var(--theme-menu-accent-color-hover);
  transform: translateZ(0) scaleY(0);
  pointer-events: none;
}
.sidebar-menu__entry__link .sidebar-menu__entry__icon {
  transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-menu__entry__link::after {
  transition: color 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-menu__entry.active:not(.open) > .sidebar-menu__entry__link::before {
  transform: translateZ(0) scaleY(1);
}

.sidebar--slim.sidebar--collapsed .sidebar-menu__entry.active > .sidebar-menu__entry__link::before {
  transform: scaleY(1);
}
.sidebar--slim.sidebar--collapsed .sidebar-submenu__entry > .sidebar-submenu__entry__link::before {
  transform: translateZ(0) scaleY(0);
}

/* Avatar Variants Definitions */
.dropdown-menu.extended-dropdown {
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  width: 100vw;
  max-width: 370px;
}
.dropdown-menu.extended-dropdown.show {
  display: flex;
}
.dropdown-menu .extended-dropdown__section {
  flex: 0 0 auto;
  padding: 0.75rem 1.25rem;
}
.dropdown-menu .extended-dropdown__section:not(:last-child) {
  border-bottom: 1px solid #E9ECEF;
}
.dropdown-menu .extended-dropdown__section--list {
  flex: 1 1 auto;
  max-height: 320px;
  padding: 0;
  overflow-y: auto;
}
.dropdown-menu .extended-dropdown__section--list > .list-group > .list-group-item {
  border-width: 0;
  margin-bottom: 0;
}
.dropdown-menu .extended-dropdown__section--list > .list-group > .list-group-item + .list-group-item {
  border-top-width: 1px;
}
.dropdown-menu .extended-dropdown__section--list > .list-group > .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu .extended-dropdown__section--list > .list-group > .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-menu a.extended-dropdown__section {
  color: #353C48;
}
.dropdown-menu a.extended-dropdown__section:hover {
  color: #868E96;
  text-decoration: none;
}

@media (max-width: 575.98px) {
  .navbar .nav .dropdown-menu.extended-dropdown,
  .navbar .navbar-nav .dropdown-menu.extended-dropdown {
    position: fixed;
    top: 56px;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: none;
  }
  .navbar .nav .dropdown-menu.extended-dropdown .extended-dropdown__section--list,
  .navbar .navbar-nav .dropdown-menu.extended-dropdown .extended-dropdown__section--list {
    max-height: none;
  }
}
/* Avatar Variants Definitions */
.fullscreen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.fullscreen__section {
  flex: 0 0 auto;
}
.fullscreen__section--center {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullscreen__section__child {
  flex: 0 1 auto;
}

@media (max-width: 767.98px) {
  .fullscreen {
    min-height: auto;
    padding: 15px;
  }
}
.nested-dropdown__submenu-item {
  position: relative;
}
.nested-dropdown__submenu-item__link {
  position: relative;
}
.nested-dropdown__submenu-item__link::after {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
}
.nested-dropdown__submenu-item__menu-wrap {
  position: absolute;
  top: -2px;
  left: 100%;
  padding-left: 2px;
}
.nested-dropdown__submenu-item__menu.dropdown-menu {
  position: static;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item:hover, .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item:active {
    background-color: transparent;
  }
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item--open .nested-dropdown__submenu-item__menu.dropdown-menu {
    display: block;
  }
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item__link::after {
    content: "\f107";
    position: static;
    right: auto;
    top: auto;
    transform: none;
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item__menu-wrap {
    position: static;
    padding: 0;
  }
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu {
    border: none;
    padding: 0;
  }
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu .dropdown-item {
    padding-left: 2rem;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nested-dropdown__submenu-item:hover .nested-dropdown__submenu-item__menu {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item:hover, .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item:active {
    background-color: transparent;
  }
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item--open .nested-dropdown__submenu-item__menu.dropdown-menu {
    display: block;
  }
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item__link::after {
    content: "\f107";
    position: static;
    right: auto;
    top: auto;
    transform: none;
    margin-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item__menu-wrap {
    position: static;
    padding: 0;
  }
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu {
    border: none;
    padding: 0;
  }
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu .dropdown-item {
    padding-left: 2rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nested-dropdown__submenu-item:hover .nested-dropdown__submenu-item__menu {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item:hover, .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item:active {
    background-color: transparent;
  }
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item--open .nested-dropdown__submenu-item__menu.dropdown-menu {
    display: block;
  }
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item__link::after {
    content: "\f107";
    position: static;
    right: auto;
    top: auto;
    transform: none;
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item__menu-wrap {
    position: static;
    padding: 0;
  }
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu {
    border: none;
    padding: 0;
  }
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu .dropdown-item {
    padding-left: 2rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nested-dropdown__submenu-item:hover .nested-dropdown__submenu-item__menu {
    display: block;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item:hover, .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item:active {
    background-color: transparent;
  }
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item--open .nested-dropdown__submenu-item__menu.dropdown-menu {
    display: block;
  }
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item__link::after {
    content: "\f107";
    position: static;
    right: auto;
    top: auto;
    transform: none;
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item__menu-wrap {
    position: static;
    padding: 0;
  }
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu {
    border: none;
    padding: 0;
  }
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu .dropdown-item {
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nested-dropdown__submenu-item:hover .nested-dropdown__submenu-item__menu {
    display: block;
  }
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item:hover, .navbar-expand .navbar-nav .nested-dropdown__submenu-item:active {
  background-color: transparent;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item--open .nested-dropdown__submenu-item__menu.dropdown-menu {
  display: block;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item__link::after {
  content: "\f107";
  position: static;
  right: auto;
  top: auto;
  transform: none;
  margin-left: 0.5rem;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item__menu-wrap {
  position: static;
  padding: 0;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu {
  border: none;
  padding: 0;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item__menu.dropdown-menu .dropdown-item {
  padding-left: 2rem;
}
.navbar-expand .navbar-nav .nested-dropdown__submenu-item:hover .nested-dropdown__submenu-item__menu {
  display: block;
}

.timeline .timeline-date {
  display: block;
  max-width: 210px;
  position: relative;
  padding-top: 8px;
}
.timeline .timeline-date:before {
  background: #DEE2E6;
  border-radius: 2px;
  bottom: -30px;
  content: "";
  height: 24px;
  left: 12px;
  position: absolute;
  width: 1px;
}
.timeline.timeline-datetime .timeline-item {
  margin-left: 117px;
}
.timeline.timeline-datetime .timeline-date {
  text-align: center;
}
.timeline.timeline-datetime .timeline-date:before {
  left: calc(50% - 1px);
}
.timeline .timeline-item {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
  position: relative;
}
.timeline .timeline-item:first-child {
  margin-top: 0;
}
.timeline .timeline-item .timeline-item-time {
  position: absolute;
  top: 10px;
  right: calc(100% + 35px);
  text-align: right;
}
.timeline .timeline-item .timeline-item-inner {
  padding: 10px;
  position: relative;
}
.timeline .timeline-item .timeline-item-head {
  border-bottom: 1px solid #DEE2E6;
  margin-bottom: 8px;
  padding-bottom: 8px;
}
.timeline .timeline-item .timeline-item-head .user-detail h5 {
  margin: 0 0 2px 0;
}
.timeline .timeline-item .timeline-item-head .post-type > p {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 0;
  line-height: 17px;
}
.timeline .timeline-item .timeline-item-head .post-time > p {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.6em;
  line-height: 15px;
  margin: 0;
}
.timeline .timeline-item .timeline-item-content p {
  margin: 0;
}
.timeline .timeline-item .timeline-icon:before {
  background: #DEE2E6;
  border-radius: 2px;
  bottom: -28px;
  content: "";
  height: 100%;
  left: -14px;
  position: absolute;
  width: 1px;
}
.timeline .timeline-item .timeline-icon i {
  left: -19px;
  position: absolute;
  top: 10px;
}

.theme-config {
  width: 200px;
  position: fixed;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateX(100%) translateY(-50%);
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 1500;
}
.theme-config--active {
  transform: translateY(-50%);
}
.theme-config .theme-config__body.card {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-config .theme-config__trigger {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
}