/* Avatar Variants Definitions */
.inputStyle:focus {
  border: 1px solid #1EB7FF;
  outline: none;
}

.otp input:focus {
  outline-offset: 0px;
}

.inputStyle {
  border: 1px solid #D4D9E2;
  height: 60px;
  width: 60px !important;
  border-radius: 10px;
  font-weight: 900;
  font-size: 1.8em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.error {
  border: 1px solid red !important;
}