/* Avatar Variants Definitions */
.float-grid-parent {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 576px) {
  .float-grid-parent.float-grid-parent__static {
    width: 540px;
  }
}
@media (min-width: 768px) {
  .float-grid-parent.float-grid-parent__static {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .float-grid-parent.float-grid-parent__static {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .float-grid-parent.float-grid-parent__static {
    width: 1140px;
  }
}

.float-col {
  padding-left: 15px;
  padding-right: 15px;
}